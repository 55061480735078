import React, { useState, useEffect } from 'react';
import '../../styles/components.css';


const ProductImages = ({ images }) => {
    const [selectedImage, setSelectedImage] = useState(images && images.main_photo);

    useEffect(() => {
        if (images) {
            setSelectedImage(images.main_photo)
        }
    }, [images]);


    const handleMouseOver = (imageUrl) => {
        setSelectedImage(imageUrl);
    }; // La primera imagen es la principal

    return (
        <div className='container-product-images'>
            <div className='selected-image'>
                <img src={selectedImage} alt="Selected" />
            </div>
            <div className='container-mini-images'>
                <div className='mini-images'>
                    <img src={images.main_photo} alt="Main" onMouseOver={() => handleMouseOver(images.main_photo)} />
                </div>
                {images.secundary_photo ?
                    <div className='mini-images'>
                        <img src={images.secundary_photo} alt="Secondary" onMouseOver={() => handleMouseOver(images.secundary_photo)} />
                    </div>
                :
                    <></>
                }
            </div>
        </div>
    );
};

export default ProductImages;