import { Provider } from 'react-redux';
import store from './store';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

//Static-Pages
import Home from './containers/pages/Home';
import About from './containers/pages/About';
import Contact from './containers/pages/Contact';
import Dates from './containers/pages/Dates';
import Error404 from './containers/errors/Error404';

//Auth
import Activate from './containers/auth/Activate';
import Login from './containers/auth/Login';
import Signup from './containers/auth/Signup';
import SignupSuccess from './containers/auth/SignupSuccess';
import ResetPassword from './containers/auth/ResetPassword';
import ResetPasswordConfirm from './containers/auth/ResetPasswordConfirm';

//Product
import Shop from './containers/products/Shop';
import Search from './containers/products/Search';
import ProductDetail from './containers/products/ProductDetail';
import Cart from './containers/cart/Cart';
import Checkout from './containers/cart/Checkout';

//Dashboard
import Dashboard from './containers/dashboard/Dashboard';
import Orders from './containers/dashboard/Orders';
import OrderDetail from './containers/dashboard/OrderDetail';
import Wishlist from './containers/dashboard/Wishlist';
import EditProfile from './containers/dashboard/EditProfile';

//Policies
import PurchasingPolicies from './containers/policies/PurchasingPolicies';
import CookiesPolicies from './containers/policies/CookiesPolicies';
import PrivacyPolicies from './containers/policies/PrivacyPolicies';
import FrequentQuestions from './containers/policies/FrequentQuestions';


function App() {
    return (
        <Provider store={store}>
            <Router>
                <Routes>
                    {/* Error Display */}
                    <Route path='*' element={<Error404 />} />

                    {/* Static-Pages */}
                    <Route exact path='/' element={<Home />} />
                    <Route exact path='/contact' element={<Contact />} />
                    <Route exact path='/dates' element={<Dates />} />
                    <Route exact path='/about' element={<About />} />

                    {/* Products */}
                    <Route exact path='/product/:productId' element={<ProductDetail />} />
                    <Route exact path='/shop' element={<Shop />} />
                    <Route exact path='/search' element={<Search />} />
                    <Route exact path='/cart' element={<Cart />} />
                    <Route exact path='/checkout' element={<Checkout />} />

                    {/* Dashboard */}
                    <Route exact path='/dashboard' element={<Dashboard />} />
                    <Route exact path='/orders' element={<Orders />} />
                    <Route exact path='/orders/detail/:transaction_id' element={<OrderDetail />} />
                    <Route exact path='/wishlist' element={<Wishlist />} />
                    <Route exact path='/edit-profile' element={<EditProfile />} />

                    {/* Authentication */}
                    <Route exact path='/activate/:uid/:token' element={<Activate />} />
                    <Route exact path='/login' element={<Login />} />
                    <Route exact path='/register' element={<Signup />} />
                    <Route exact path='/signup-success' element={<SignupSuccess />} />
                    <Route exact path='/reset_password' element={<ResetPassword />} />
                    <Route exact path='/password/reset/confirm/:uid/:token' element={<ResetPasswordConfirm />} />

                    {/* PoliciesCookiesPolicies */}
                    <Route exact path='/purchasing-policies' element={<PurchasingPolicies />} />
                    <Route exact path='/cookies-policies' element={<CookiesPolicies />} />
                    <Route exact path='/privacy-policies' element={<PrivacyPolicies />} />
                    <Route exact path='/frequents-questions' element={<FrequentQuestions />} />

                    
                </Routes>
            </Router>
        </Provider>
    );
}

export default App;
