// Components
import LayoutWithoutFooter from '../../hocs/LayoutWithoutFooter';
import { DashboardMenu } from '../../components/aditional/DashboardMenu';
import CartItem from '../../components/cart/CartItem';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// Styles
import '../../styles/cart.css';
// Redux
import { connect } from 'react-redux';
import { setAlert } from '../../redux/actions/alert';
import { get_user_profile } from '../../redux/actions/profile';
import { remove_item, update_item, get_items, get_total, get_item_total, synch_cart } from "../../redux/actions/cart";
// Icons
import { PiArticle, PiChatText, PiUser, PiHeartLight } from "react-icons/pi";
import { IoBagHandleOutline, IoHeartOutline } from 'react-icons/io5';
import { LuArrowLeft } from "react-icons/lu";


const Cart = ({
	get_user_profile,
	profile,
	get_items,
	items,
	get_total,
	get_item_total,
	amount,
	compare_amount,
	total_items,
	isAuthenticated,
	remove_item,
	update_item,
	synch_cart,
	setAlert,
}) => {

	const [render, setRender] = useState(false);
	const [totalQuantity, setTotalQuantity] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
        get_items();
        get_total();
        get_item_total();
        get_user_profile();
    }, [render])

    useEffect(() => {
        calculateTotalQuantity();
    }, [amount])
        

    const calculateTotalQuantity = () => {
    	let total = 0

		if (items && items !== null && items !== undefined && items.length > 0) { 
        	items.forEach(item => {
            	total += parseInt(item.count, 10);
        	})
    	}
        setTotalQuantity(total);
    };

    const showItems = () => {
    	return (
    		<div className='container-cart-items'>
				{
					items &&
					items !== null &&
					items !== undefined &&
					items.length !== 0 &&
					items.map((item, index) => {
						let count = item.count;

						return (
							<div key={index}>
								<CartItem
									index={index}
									item={item}
									count={count}
									update_item={update_item}
									remove_item={remove_item}
									render={render}
									setRender={setRender}
									setAlert={setAlert}
								/>
							</div>
						)
					})
				}
			</div>
    	)
    };

    const showWhislistItems = () => {

    }

    const checkoutButton = () => {
    	if (!isAuthenticated) {
    		return (
    			<div className='contain-checkout-button'>
    				<Link to='/login' style={{textDecoration: 'none'}} className='checkout-button'>Iniciar Sesión</Link>
    			</div>
    		)
    	} else {
    		return (
    			<div className='contain-checkout-button'>
    				<Link to='/checkout' style={{textDecoration: 'none'}} className='checkout-button'>Ir a Pagar</Link>
    			</div>
    		)
    	}
	}

	return (
		<LayoutWithoutFooter>
			<div className='container-banner-all cart'>
				<h3>Bolsa de compras</h3>
			</div>
			<div className='container-dashboard'>
				<DashboardMenu className='container-dashboard-items' />
				<div className='container-all-cart'>
					{
						items &&
						items !== null &&
						items !== undefined &&
						items.length !== 0 ? (
							<div className='container-items-cart'>
								<div className='container-cart-item phone-titles-cart'>
									<h4>Producto</h4>
									<div></div>
									<h4>Cantidad</h4>
									<h4>Stock</h4>
									<h4>Precio</h4>
									<div></div>
								</div>
								{showItems()}
							</div>
						) : (
							<div className='container-empty-cart'>
								<div className='container-cart-title'>
									<h2>No hay productos en tu bolsa</h2>
								</div>
								<div className='cart-back-to-goods'>
									<Link to='/shop' className='link-back-to-shop' style={{textDecoration: 'none'}}>Volver a la tienda</Link>
								</div>
							</div>
						)
					}
				</div>
			</div>
			<div className='checkout-items'>
				<div>
					<Link to='/shop' className='link-back-to-shop' style={{textDecoration: 'none'}}><LuArrowLeft /> Seguir comprando</Link>
				</div>
				<div className='container-checkout-footer'>
					<div className='checkout-item'>
						<p>Subtotal</p>
						<p>$ {amount && amount.toFixed(2)}</p>
					</div>
					<div className='checkout-item'>
						<p>Cantidad</p>
						<p>{totalQuantity}</p>
					</div>
					{checkoutButton()}
				</div>
			</div>
		</LayoutWithoutFooter>
	)
}

const mapStateToProps = state => ({
	isAuthenticated: state.Auth.isAuthenticated,
	items: state.Cart.items,
	amount: state.Cart.amount,
	compare_amount: state.Cart.compare_amount,
	total_items: state.Cart.total_items,
	profile: state.Profile.profile,
})

export default connect(mapStateToProps, {
	get_items,
	get_total,
	get_item_total,
	remove_item,
	update_item,
	setAlert,
	get_user_profile,
	synch_cart
}) (Cart)