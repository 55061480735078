//Components
import { useState } from 'react';
//Styles
import '../../styles/components.css'
//Icons
import { TiStarFullOutline } from "react-icons/ti";


const StarRating = ({ onChange, rating}) => {
    const [hoverRating, setHoverRating] = useState(0);
    
    // Rango de estrellas
    const totalStars = 5;

    return (
        <div className='star-rating-container'>
            {[...Array(totalStars)].map((_, index) => {
                const ratingValue = index + 1;
                return (
                    <button
                        type="button"
                        key={index}
                        onClick={() => onChange({ target: { name: 'rating', value: ratingValue } })}
                        onMouseEnter={() => setHoverRating(ratingValue)}
                        onMouseLeave={() => setHoverRating(0)}
                        className="button-content-stars"
                    >
                        <TiStarFullOutline
                            className={`star-rating-button ${
                                ratingValue <= (hoverRating || rating)
                                    ? 'yellow-star'
                                    : 'gray-star'
                            }`}
                        />
                    </button>
                );
            })}
        </div>
    );
};

export default StarRating;