// Components
import Layout from '../../hocs/Layout';
import { DashboardMenu } from '../../components/aditional/DashboardMenu';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router';
import { useEffect, useState } from 'react';
// Styles
import '../../styles/navigation.css';
// Redux
import { connect } from 'react-redux';
import { get_user_profile, update_user_profile } from '../../redux/actions/profile';
// Icons
import { FaCheck } from "react-icons/fa6";


const EditProfile = ({
	update_user_profile,
	get_user_profile,
	profile,
}) => {

	const [favoritesVisible, setFavoritesVisible] = useState(false);
	const [redirect, setRedirect] = useState(false);
	const [formData, setFormData] = useState({
        address_line_1: '',
        address_line_2: '',
        city: '',
        state_province_region: '',
        phone: '',
        country_region: ''
    });

	useEffect(() => {
        window.scrollTo(0, 0);
        get_user_profile()
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;
            setFormData({
                ...formData,
                [name]: value
            });
    };

    useEffect(() => {
        if (profile) {
            setFormData({
                address_line_1: profile.address_line_1 || '',
                address_line_2: profile.address_line_2 || '',
                city: profile.city || '',
                state_province_region: profile.state_province_region || '',
                phone: profile.phone || '',
                country_region: profile.country_region || ''
                
            });
        }
    }, [profile]);

    const toggleFavorite = () => {
		setFavoritesVisible(!favoritesVisible);
	};

	const onSubmit = async (e) =>{
    	e.preventDefault();
	    await update_user_profile(formData);
	    setRedirect(true);
  	};

  	if (redirect) {
    	return <Navigate to='/dashboard' />;
  	}

	return (
		<Layout>
			<div className='container-banner-all profile'>
				<h3>Editar Perfil</h3>
			</div>
			<div className='container-dashboard'>
				<DashboardMenu />
				<form className='container-profile' onSubmit={e => onSubmit(e)}>
					<div className='profile-info-edit'>
						<div className='input-edit'>
							<input
					            type='text'
					            name='phone'
					            value={formData.phone}
					            onChange={handleChange}
					            className='input-contact-form'
					        />
							<div className='label-line'>Teléfono</div>
						</div>
						<div className='input-edit'>
							<input
					            type='text'
					            name='address_line_1'
					            value={formData.address_line_1}
					            onChange={handleChange}
					            className='input-contact-form'
					        />
					        <div className='label-line'>Dirección</div>
					    </div>
					    <div className='input-edit'>
							<input
					            type='text'
					            name='address_line_2'
					            value={formData.address_line_2}
					            onChange={handleChange}
					            className='input-contact-form'
					        />
					        <div className='label-line'>Dirección</div>
					    </div>
						<div className='input-edit'>
							<input
					            type='text'
					            name='city'
					            value={formData.city}
					            onChange={handleChange}
					            required
					            className='input-contact-form'
					        />
							<div className='label-line'>Ciudad</div>
						</div>
						<div className='input-edit'>
							<input
					            type='text'
					            name='state_province_region'
					            value={formData.state_province_region}
					            onChange={handleChange}
					            required
					            className='input-contact-form'
					        />
							<div className='label-line'>Estado o provincia</div>
						</div>
						<div className='input-edit'>
							<input
					            type='text'
					            name='country_region'
					            value={formData.country_region}
					            onChange={handleChange}
					            required
					            className='input-contact-form'
					        />
							<div className='label-line'>País</div>
						</div>
					</div>
					<div className='container-profile-handler'>
						<button className='logout-button' type='submit'><FaCheck />Guardar cambios</button>
					</div>
				</form>
			</div>
		</Layout>
	)
}

const mapStateToProps = state => ({
	isAuthenticated: state.Auth.isAuthenticated,
	profile: state.Profile.profile,
})

export default connect(mapStateToProps, {
	get_user_profile,
	update_user_profile,
}) (EditProfile)