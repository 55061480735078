import { FaStar, FaRegStar, FaStarHalfAlt } from 'react-icons/fa';
import '../../styles/cards.css';
import { IoMdHeartEmpty } from "react-icons/io";
import { Link } from 'react-router-dom';
import { IoBagHandleOutline } from "react-icons/io5";
import { useEffect, useState } from 'react';


export const ShopCard = ({ product, addToCart }) => {

	const [stock, setStock] = useState(false);

	useEffect(() => {
		const isProductInStock = product.quantity > 0;
        setStock(isProductInStock);
	}, [product.quantity]);

	return (
		<div className='container-list-card'>
			<div className='list-card-image'>
				{!stock &&<div className='stocked-product'>
					<p className='stocked-text'>Sin Stock</p>
				</div>}
				<Link to={`/product/${product.id}`}><img src={product && product.main_photo} alt={product.name} /></Link>
			</div>
			<div className='container-discount'>
			{product && product.discount_percentage && product.discount_percentage != 0.00 ?
				<p className='discount-text'>
					{Math.floor(product.discount_percentage)} %
				</p>
				:
				<></>
			}</div>
			<div className='list-card-info'>
				<Link to={`/product/${product.id}`} style={{textDecoration: 'none'}}><h4 className='list-card-title'>{product.name}</h4></Link>
				<div className='container-price-bag'>
					{stock ? (<button className='button-bag' onClick={() => addToCart(product)}><IoBagHandleOutline  /></button>) : (<></>)}
					<p className={`list-card-price ${stock ? 'stock-card-price' : ''}`}>$ {product.final_price}</p>
				</div>
			</div>
		</div>
	)
};