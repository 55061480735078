import '../../styles/components.css'
import { IoCartOutline } from "react-icons/io5";
import { IoMdHeartEmpty } from "react-icons/io";
import { Link } from 'react-router-dom';
import StarsCard from '../products/StarsCard';


export const RelatedCard = ({ id, image, name, price, rating, description }) => {
	return (
		<div className='container-related-card'>
			<div className='card-image'>
				<Link to={`/product/${id}`} target='_blank'><img src={image} alt={name} /></Link>
			</div>
			<div className='related-card-info'>
				<Link to={`/product/${id}`} target='_blank' style={{textDecoration: 'none'}}><h4 className='related-card-title'>{name}</h4></Link>
				<p>$ {price}</p>
				<div className='rating-related'>
					<StarsCard rating={rating} />
				</div>
			</div>
		</div>
	)
};