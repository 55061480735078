export const prices = [
	{
		id: 0,
		name: 'Todo'
	},
	{
		id: 1,
		name: '1000 - 19999'
	},
	{
		id: 2,
		name: '20000 - 39999'
	},
	{
		id: 3,
		name: '40000 - 59999'
	},
	{
		id: 4,
		name: '60000 - 79999'
	},
	{
		id: 5,
		name: 'Más de 80000'
	}
]