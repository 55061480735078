//Components
import Layout from '../../hocs/Layout';
import { useEffect } from 'react';
import { ShopCard } from '../../components/cards/ShopCard';
//Redux
import { get_items, get_total, get_item_total, add_item } from '../../redux/actions/cart';
import { connect } from 'react-redux';
//Styles
import '../../styles/products.css';


const Search = ({
	search_products,
	get_items,
	get_total,
	get_item_total,
	add_item
}) => {

	useEffect(() => {
		window.scrollTo(0,0)
	}, []);

	const addToCart = async (product) => {
	    if (product && product !== null && product !== undefined && product.quantity > 0) {
	        await add_item(product);
	        await get_items();
	        await get_total();
	        await get_item_total();
	    }
	}

	const showProducts = () => {
		let items = [];

        items = Array.isArray(search_products) && search_products.length > 0 ? search_products : [];

		if (items.length > 0) {
        	return items.map((product, index) => (
        		<div className='container-shop'>
            		<div key={index} className='product-card-wrapper'>
                		<ShopCard product={product} addToCart={addToCart} />
            		</div>
        		</div>
        	));
    	} else {
        	return <p>No se encontraron resultados de tu búsqueda</p>;
    	}
	}

	return (
		<Layout>
			<div className='container-banner-all shop'>
				<h3>Resultados de tu búsqueda</h3>
			</div>
			<div className='container-search'> 
				{showProducts()}
			</div>
		</Layout>
	);
};

const mapStateToProps = state => ({
	search_products: state.Products.search_products
})

export default connect(mapStateToProps, {
	get_items,
	get_total,
	get_item_total,
	add_item
}) (Search)