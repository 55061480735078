import { combineReducers } from 'redux';
import Alert from './alert';
import Auth from './auth';
import Cart from './cart';
import Categories from './categories';
import Dates from './dates';
import Coupons from './coupons';
import Orders from './orders';
import Payment from './payment';
import Products from './products';
import Profile from './profile';
import Reviews from './reviews';
import Shipping from './shipping';
import Wishlist from './wishlist';


export default combineReducers({
	Alert,
	Auth,
	Cart,
	Categories,
	Coupons,
	Dates,
	Orders,
	Payment,
	Products,
	Profile,
	Reviews,
	Shipping,
	Wishlist,
})