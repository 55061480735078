import Layout from '../../hocs/Layout';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { reset_password } from '../../redux/actions/auth';
import { Oval } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router';


const ResetPassword = ({
 	reset_password, loading
}) => {

	useEffect(() => {
		window.scrollTo(0,0)
	}, []);

	const [requestSent, setRequestSent] = useState(false);
	const [formData, setFormData] = useState({
		email: ''
	});


	const {
		email
	} = formData;


	const onChange = e => setFormData({...formData, [e.target.name]: e.target.value });

	const onSubmit = e =>{
    	e.preventDefault();
    	reset_password(email);
    	setRequestSent(true);
  	};

  	if (requestSent && !loading)
  		return <Navigate to='/' />;

	return (
		<Layout>
			<div className='container-banner-all auth'>
				<h3>Cambiar Contraseña</h3>
			</div>
			<div className='container-form'>
				<form className='form-content' onSubmit={e => onSubmit(e)}>
					<div className='contain-form'>
						<img className='contain-img-auth' src={require(`../../images/logojoyitaoscuro.png`)} />
						<div className='input-auth'>
		                    <input
		                    	type='email'
		                    	name='email'
		                    	value={email}
		                    	onChange={e => onChange(e)}
		                    	className='input-contact-form'
		                    	required
		                    />
		                    <div className='label-line'>Correo Electrónico</div>
		                </div>
						{loading ? 
							<button type='button' className='button-form'><Oval
  								visible={true}
								height="20"
								width="20"
								color="#ddd"
								ariaLabel="oval-loading"
								wrapperStyle={{}}
								wrapperClass=""
								secundaryColor="#ddd"
							/>
							</button> 
						: 
							<button type='submit' className='button-form'>
								Enviar email
							</button>
						}
					</div>
				</form>
			</div>
		</Layout>
	);
}

const mapStateToProps = state => ({
	loading: state.Auth.loading
})

export default connect(mapStateToProps, {
	reset_password 
}) (ResetPassword)