import {
    CREATE_DATES_SUCCESS,
    CREATE_DATES_FAIL,
} from '../actions/types';

const initialState = {
    appointments: [],  // Manejar múltiples citas
    loading: false,
    error: null,
};

export default function dates(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case CREATE_DATES_SUCCESS:
            return {
                ...state,
                appointments: [...state.appointments, payload],
                loading: false,
                error: null
            };
        case CREATE_DATES_FAIL:
            return {
                ...state,
                loading: false,
                error: 'No se pudo crear la cita'
            };
        default:
            return state;
    }
}