import { Fragment } from 'react';
import '../../styles/components.css';


const ShippingForm = ({
    full_name,
    address_line_1,
    address_line_2,
    city,
    state_province_region,
    postal_zip_code,
    telephone_number,
    onChange,
    buy,
    user,
    renderShipping,
    total_amount,
    original_price,
    estimated_tax,
    shipping_cost,
    shipping_id,
    shipping,
    apply_coupon,
    coupon,
    coupon_name,
    total_after_coupon,
    confirmPay
}) => {

    return (
        <div aria-labelledby="summary-heading" className='container-shipping-checkout'>
            <h2 id="summary-heading" className='shipping-title'>Resumen de la Orden</h2>
            <div className='contain-shipping-checkout'>
                <div className='container-shipping-options'>
                    {renderShipping()}
                </div>
                <div className="flex items-center justify-between">
                    <form onSubmit={e => apply_coupon(e)} className='container-form-coupon'>
                        <div className='input-shipping'>
                            <input
                                name='coupon_name'
                                type='text'
                                onChange={e => onChange(e)}
                                value={coupon_name}
                                className='input-contact-form'
                            />
                            <div className='label-line'>Cupón de Descuento</div>
                        </div>
                        <button type="submit" className='checkout-button'>Aplicar Cupón</button>
                    </form>
                </div>
                {
                    coupon && 
                    coupon !== null &&
                    coupon !== undefined ? (
                        <div className=''>
                            Cupón: {coupon.name} aplicado.
                        </div>
                    ) : (
                        <Fragment></Fragment>
                    )
                }
                <div className='checkout-item'>
                    <p className=''>Subtotal</p>
                    <p className=''>$ {original_price}</p>
                </div>
                <div className='checkout-item-form'>
                    <p>Costo de Envío</p>
                    <div className=''>
                        {shipping && shipping_id !== 0 ? 
                            <p>$ {shipping_cost}</p>
                        :
                            <p className='text-select-option'>(Elija una opción de envío)</p>
                        }
                    </div>
                </div>
                <div className='checkout-item'>
                    <p className="flex text-sm text-gray-600">IVA</p>
                    <p className=''>${estimated_tax}</p>
                </div>
                {
                    coupon && 
                    coupon !== null && 
                    coupon !== undefined ?
                        <>
                            <div className='checkout-item'>
                                <dt className="flex text-sm text-gray-600">
                                    <span>Descuento Total</span>
                                </dt>
                                <dd className="text-sm font-medium text-gray-900">$ {total_after_coupon}</dd>
                            </div>
                            <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                                <dt className="text-base font-medium text-gray-900">Total</dt>
                                <dd className="text-base font-medium text-gray-900">$ {total_amount}</dd>
                            </div>
                        </>
                    :
                        <div className='checkout-item'>
                            <dt className="text-base font-medium text-gray-900">Total</dt>
                            <dd className="text-base font-medium text-gray-900">$ {total_amount}</dd>
                        </div>
                }
            </div>
            <form onSubmit={e => buy(e)} className='container-shipping-form'>
                <div className=" px-4 py-5  mt-4 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Datos de envio:</h3>
                </div>
                <div className='input-shipping'>
                    <input
                        type='text'
                        name='full_name'
                        onChange={e => onChange(e)}
                        value={full_name}
                        required
                        className='input-contact-form'
                    />
                    <div className='label-line'>Nombre y Apellido*</div>
                </div>
                <div className='input-shipping'>
                    <input
                        type='text'
                        name='address_line_1'
                        onChange={e => onChange(e)}
                        value={address_line_1}
                        required
                        className='input-contact-form'
                    />
                    <div className='label-line'>Dirección*</div>
                </div>
                <div className='input-shipping'>
                    <input
                        type='text'
                        name='address_line_2'
                        onChange={e => onChange(e)}
                        value={address_line_2}
                        className='input-contact-form'
                    />
                    <div className='label-line'>Dirección Secundaria</div>
                </div>
                <div className='input-shipping'>
                    <input
                        type='text'
                        name='city'
                        onChange={e => onChange(e)}
                        value={city}
                        required
                        className='input-contact-form'
                    />
                    <div className='label-line'>Ciudad*</div>
                </div>
                <div className='input-shipping'>
                    <input
                        type='text'
                        name='state_province_region'
                        onChange={e => onChange(e)}
                        value={state_province_region}
                        required
                        className='input-contact-form'
                    />
                    <div className='label-line'>Provincia*</div>
                </div>
                <div className='input-shipping'>
                    <input
                        type='text'
                        name='postal_zip_code'
                        onChange={e => onChange(e)}
                        value={postal_zip_code}
                        required
                        className='input-contact-form'
                    />
                    <div className='label-line'>Código Postal*</div>
                </div>
                <div className='input-shipping'>
                    <input
                        type='tel'
                        name='telephone_number'
                        onChange={e => onChange(e)}
                        value={telephone_number}
                        required
                        className='input-contact-form'
                    />
                    <div className='label-line'>Teléfono*</div>
                </div>
                {confirmPay ? (
                    <></>
                ) : (
                    shipping_id !== 0 ? (
                        <button type='submit' className='checkout-button'>Confirmar</button>
                    ) : (
                        <p className='checkout-text-confirm'>Confirmar</p>
                ))
                    
                }
            </form>
        </div>
    )
}

export default ShippingForm