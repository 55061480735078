import {
	GET_PRODUCTS_SUCCESS,
	GET_PRODUCTS_FAIL,
	GET_PRODUCTS_BY_ARRIVAL_SUCCESS,
	GET_PRODUCTS_BY_ARRIVAL_FAIL,
	GET_PRODUCTS_BY_SOLD_SUCCESS,
	GET_PRODUCTS_BY_SOLD_FAIL,
	GET_PRODUCT_SUCCESS,
	GET_PRODUCT_FAIL,
	SEARCH_PRODUCTS_SUCCESS,
	SEARCH_PRODUCTS_FAIL,
	RELATED_PRODUCTS_SUCCESS,
	RELATED_PRODUCTS_FAIL,
	FILTER_PRODUCTS_SUCCESS,
	FILTER_PRODUCTS_FAIL,
	CATEGORY_PRODUCTS_SUCCESS,
	CATEGORY_PRODUCTS_FAIL,
	HIGHLIGHT_PRODUCTS_SUCCESS,
	HIGHLIGHT_PRODUCTS_FAIL,
} from '../actions/types'


const initialState = {
	products: null,
	products_arrival: null,
	products_sold: null,
	product: null,
	search_products: null,
	related_products: null,
	filtered_products: null,
	count: 0,
    next: null,
    previous: null,
    popular_products: null,
    top_selling: null,
    new_arrivals: null,
}

export default function Products(state = initialState, action) {
	const { type, payload } = action;

	switch(type) {
		case GET_PRODUCTS_SUCCESS:
			return {
				...state,
				products: action.payload.results,
				count: action.payload.count,
				next: action.payload.next,
				previous: action.payload.previous,
			}
		case GET_PRODUCTS_FAIL:
			return {
				...state,
				products: [],
				count: 0,
				next: null,
				previous: null,
			}
		case GET_PRODUCTS_BY_ARRIVAL_SUCCESS:
			return {
				...state,
				products_arrival: payload.products
			}
		case GET_PRODUCTS_BY_ARRIVAL_FAIL:
			return {
				...state,
				products_arrival: null
			}
		case GET_PRODUCTS_BY_SOLD_SUCCESS:
			return {
				...state,
				products_sold: payload.products
			}
		case GET_PRODUCTS_BY_SOLD_FAIL:
			return {
				...state,
				products_sold: null
			}
		case GET_PRODUCT_SUCCESS:
			return {
				...state,
				product: payload.product
			}
		case GET_PRODUCT_FAIL:
			return {
				...state,
				product: null
			}
		case RELATED_PRODUCTS_SUCCESS:
			return {
				...state,
				related_products: payload.related_products
			}
		case RELATED_PRODUCTS_FAIL:
			return {
				...state,
				related_products: null
			}
		case FILTER_PRODUCTS_SUCCESS:
			return {
				...state,
				filtered_products: action.payload.results.filtered_products,
				count: action.payload.count,
				next: action.payload.next,
				previous: action.payload.previous,
			}
		case FILTER_PRODUCTS_FAIL:
			return {
				...state,
				filtered_products: [],
				count: 0,
				next: null,
				previous: null,
			}
		case SEARCH_PRODUCTS_SUCCESS:
			return {
				...state,
				search_products: action.payload.results.search_products,
				count: action.payload.count,
				next: action.payload.next,
				previous: action.payload.previous,
			}
		case SEARCH_PRODUCTS_FAIL:
			return {
				...state,
				search_products: [],
				count: 0,
				next: null,
				previous: null,
			}
		case CATEGORY_PRODUCTS_SUCCESS:
			return {
				...state,
				category_products: action.payload.results.category_products,
				count: action.payload.count,
				next: action.payload.next,
				previous: action.payload.previous,
			}
		case CATEGORY_PRODUCTS_FAIL:
			return {
				...state,
				category_products: [],
				count: 0,
				next: null,
				previous: null,
			}
		case HIGHLIGHT_PRODUCTS_SUCCESS:
			return {
				...state,
				popular_products: payload.popular_products,
			    top_selling: payload.top_selling_products,
			    new_arrivals: payload.new_arrivals,
			}
		case HIGHLIGHT_PRODUCTS_FAIL:
			return {
				...state,
				popular_products: null,
    			top_selling: null,
    			new_arrivals: null,
			}
		default:
			return state
	}
}