// Components
import Layout from '../../hocs/Layout';
import { DashboardMenu } from '../../components/aditional/DashboardMenu';
import { Navigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
// Styles
import '../../styles/dashboard.css';
// Redux
import { connect } from 'react-redux';
import { get_user_profile } from '../../redux/actions/profile';
import { logout } from '../../redux/actions/auth';
// Icons
import { FaArrowRightArrowLeft, FaArrowRightFromBracket, FaPenToSquare } from "react-icons/fa6";


const Dashboard = ({
	logout,
	get_user_profile,
	profile,
}) => {

	const [render, setRender] = useState(false);
	const [favoritesVisible, setFavoritesVisible] = useState(false);
	const [redirect, setRedirect] = useState(false);

	

	useEffect(() => {
        window.scrollTo(0, 0);
        get_user_profile()
    }, [])

    const toggleFavorite = () => {
		setFavoritesVisible(!favoritesVisible);
	};

	const logoutHandler = () => {
		logout();
		setRedirect(true);
	}
	if (redirect) {
		window.location.reload(false);
		return <Navigate to='/' />
	}

	return (
		<Layout>
			<div className='container-banner-all profile'>
				<h3>Perfil</h3>
			</div>
			<div className='container-dashboard'>
				<DashboardMenu />
				<div className='container-profile'>
					<div className='profile-info'>
						<p className='profile-info-text'>Nombre y Apellido:<span>{profile && profile.full_name}</span></p>
						<p className='profile-info-text'>Email:<span>{profile && profile.email}</span></p>
						<p className='profile-info-text'>Teléfono:<span>{profile && profile.phone}</span></p>
						<p className='profile-info-text'>Dirección:<span>{profile && profile.address_line_1}</span></p>
						<p className='profile-info-text'>Dirección secundaria:<span>{profile && profile.address_line_2}</span></p>
						<p className='profile-info-text'>Ciudad:<span>{profile && profile.city}</span></p>
						<p className='profile-info-text'>Estado o provincia:<span>{profile && profile.state_province_region}</span></p>
						<p className='profile-info-text'>País:<span>{profile && profile.country_region}</span></p>
						<p className='profile-info-text'>Código Postal:<span>{profile && profile.zipcode}</span></p>
					</div>
					<div className='container-profile-handler'>
						<Link to='/edit-profile' className='profile-handler-link' style={{textDecoration: 'none'}}><FaPenToSquare />Editar perfil</Link>
						<Link to='/reset_password' className='profile-handler-link' style={{textDecoration: 'none'}}><FaArrowRightArrowLeft />Cambiar contraseña</Link>
						<button className='logout-button' onClick={logoutHandler}><FaArrowRightFromBracket />Cerrar sesión</button>
					</div>
				</div>
			</div>
		</Layout>
	)
}

const mapStateToProps = state => ({
	isAuthenticated: state.Auth.isAuthenticated,
	profile: state.Profile.profile,
})

export default connect(mapStateToProps, {
	logout,
	get_user_profile,
}) (Dashboard)