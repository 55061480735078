import { useState, useEffect } from 'react';
import '../../styles/cart.css';
import { IoMdClose, IoIosRefresh } from "react-icons/io";


const CartItem = ({
	index,
	item,
	count,
	update_item,
	remove_item,
	render,
	setRender,
	setAlert,
}) => {

	const [stock, setStock] = useState(false);
	const [showCustomInput, setShowCustomInput] = useState(false);
	const [customCount, setCustomCount] = useState('');
	const [formData, setFormData] = useState({
		item_count: 1
	});
	const { item_count } = formData;

	useEffect(() => {
		if (count) {
		setFormData({...formData, item_count: count});
	}
	}, [count]);

	const handleSelectChange = (e) => {
        const value = e.target.value;
        setFormData({ ...formData, item_count: value });

        if (value === 'Mas de 9') {
            setShowCustomInput(true);
        } else {
            setShowCustomInput(false);
            setCustomCount(''); // Reset custom input if selecting a specific number
        }
    };

    const handleCustomInputChange = (e) => {
        setCustomCount(e.target.value);
        setFormData({ ...formData, item_count: e.target.value });
    };

	const onSubmit = e=> {
		e.preventDefault();

		const fetchData = async () => {
			try {
				if (item.product.quantity >= item_count) {
					await update_item(item, item_count);
				} else {
					setAlert('Not enough in stock', 'bad')
				}
				setRender(!render)
			} catch (err) {

			}
		}

		fetchData();
	}

	useEffect(() => {
		if (item && item.product.quantity >= item_count) {
			setStock(true);
		} else {
			setStock(false);
		}
	}, [item.product.quantity, item_count]);

	const removeItemHandler = async () => {
		await remove_item(item);
		setRender(!render);
	}

	return (
		<div className='container-cart-item'>
			<div className='cart-item-image item-back'>
				<img src={item.product && item.product.main_photo} alt={item.product && item.product.name} />
			</div>
			<div className='item-product-info'>
				<h4>{item.product && item.product.name}</h4>
			</div>
			<form onSubmit={e => onSubmit(e)} className='container-form-count'>
				<select
					name='item_count'
					onChange={handleSelectChange}
					value={item_count}
					className='select-count'
				>
					<option>1</option>
					<option>2</option>
					<option>3</option>
					<option>4</option>
					<option>5</option>
					<option>6</option>
					<option>7</option>
					<option>8</option>
					<option>9</option>
					<option>Mas de 9</option>
				</select>
				{showCustomInput && (
	                <input
	                    type='number'
	                    min='10'
	                    value={customCount}
	                    onChange={handleCustomInputChange}
	                    placeholder='Especifica la cantidad'
	                />
	            )}
				<button type='submit' className='update-button'>
					<IoIosRefresh />
				</button>
			</form>
			<div className='cart-item-stock'>
				{stock ? 
					<p>Disponible: {item.product && item.product.quantity}</p>
				:
					<p>Sin Stock</p>
				}
			</div>
			<div>
				<p className='cart-item-price'>$ {item.product && item.product.final_price}</p>
			</div>
			<div className='cart-remove-contain'>
				<button className='button-remove' onClick={removeItemHandler}>
					<IoMdClose />
				</button>
			</div>
			
		</div>
	)
}

export default CartItem