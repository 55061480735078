// Components
import Layout from '../../hocs/Layout';
import { DashboardMenu } from '../../components/aditional/DashboardMenu';
import { WishCard } from '../../components/cards/WishCard';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router';
import { useEffect, useState } from 'react';
// Styles
import '../../styles/dashboard.css';
// Redux
import { connect } from 'react-redux';
import { get_wishlist_items, remove_wishlist_item } from '../../redux/actions/wishlist';
// Icons
import { IoBagHandleOutline, IoHeartOutline } from 'react-icons/io5';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { PiArticle, PiChatText, PiUser, PiHeartLight } from "react-icons/pi";
import { FaArrowRightArrowLeft, FaArrowRightFromBracket, FaPenToSquare } from "react-icons/fa6";


const Wishlist = ({
	get_wishlist_items,
	remove_wishlist_item,
	items,
}) => {

	const [render, setRender] = useState(false);
	const [favoritesVisible, setFavoritesVisible] = useState(false);
	const [redirect, setRedirect] = useState(false);

	useEffect(() => {
        window.scrollTo(0, 0);
        get_wishlist_items()
    }, [render])

    const removeItem = async (item) => {
		await remove_wishlist_item(item.product.id);
	}

    const showFavorites = () => {
		let display = [];

    	if (items &&
			items !== null &&
			items !== undefined &&
			items.length !== 0) {
    			items.forEach((item, index) => {
					display.push(
						<div key={index} className='cards-contain-wish'>
							<WishCard
								item={item}
								render={render}
								setRender={setRender}
								removeItem={removeItem}
							/>
						</div>			
					)
				}
			)
		}
    	return display;
    };

	return (
		<Layout>
			<div className='container-banner-all profile'>
				<h3>Favoritos</h3>
			</div>
			<div className='container-dashboard'>
				<DashboardMenu />
				<div className='container-wish-list'>
					{showFavorites()}
				</div>
			</div>

		</Layout>
	)
}

const mapStateToProps = state => ({
	isAuthenticated: state.Auth.isAuthenticated,
	items: state.Wishlist.items,
})

export default connect(mapStateToProps, {
	get_wishlist_items,
	remove_wishlist_item,
}) (Wishlist)