//Components
import Layout from '../../hocs/Layout';
import { connect } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { ShopCard } from '../../components/cards/ShopCard';
import { prices } from '../../helpers/fixedPrices';
import { materials } from '../../helpers/materialType';
import { Disclosure } from '@headlessui/react';
import { useParams } from 'react-router';
//Redux
import { useSelector } from 'react-redux';
import { get_categories } from '../../redux/actions/categories';
import { get_products, get_filtered_products, get_search_products, get_category_products } from '../../redux/actions/products';
import { get_items, get_total, get_item_total, add_item } from '../../redux/actions/cart';
import { get_product_categories } from '../../redux/actions/categories';
//Styles
import '../../styles/products.css';
//Icons
import { PiMagnifyingGlass } from "react-icons/pi";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';


const Shop = ({
	get_categories,
	categories,
	get_products,
	products,
	get_filtered_products,
	filtered_products,
	get_search_products,
	search_products,
	get_category_products,
	category_products,
	get_items,
	get_total,
	get_item_total,
	add_item,
}) => {

	const params = useParams();
	const categoryName = params.categoryName
	const [categoryId, setCategoryId] = useState(null);
	const productsState = useSelector(state => state.Products);
	const [filterByCategory, setFilterByCategory] = useState(false);
	const [filterByAnother, setFilterByAnother] = useState(false);
	const [searchProduct, setSearchProduct] = useState('');
	const searchFormRef = useRef();
	const [page, setPage] = useState(1);
	const [formData, setFormData] = useState({
		material_type: '',
		price_range: 'Any',
		sortBy: 'date_created',
		order: 'desc'
	})

	const {
		material_type,
		price_range,
		sortBy,
		order
	} = formData

	useEffect(() => {
		window.scrollTo(0,0)
		get_categories()
		get_products(page)
		return () => {
            get_search_products('');
        }
	}, []);

	useEffect(() => {
	    window.scrollTo(0, 0);
	    if (filterByCategory && page) {
	        get_category_products(categoryId, page);
	    } else if (filterByAnother && page) {
	        get_filtered_products(material_type, price_range, sortBy, order, page);
	    } else if (page) {
	        get_products(page);
	    }
	}, [page, filterByCategory, filterByAnother]);

	const onChange = e => {
		setFormData({...formData, [e.target.name]: e.target.value})
	}

	const onChangeCategory = e => {
		setCategoryId(e.target.value)
		setPage(1);
	}

	const onSearchChange = (e) => {
		setSearchProduct(e.target.value);
		get_products(1);
		get_category_products(0);
		setFilterByCategory(false);
		setFilterByAnother(false);
	}

	const onSubmitFilter = e => {
		e.preventDefault();
		get_filtered_products(material_type, price_range, sortBy, order, 1)
		setFilterByAnother(true);
		setFilterByCategory(false);
		setPage(1);
	}

	const onSubmitSearch = e => {
		e.preventDefault();
		get_search_products(searchProduct, 1);
        searchFormRef.current.reset();
        setSearchProduct('');
        setPage(1);
	}

	const onSubmitCategory = (e, id) => {
		e.preventDefault();
		setCategoryId(id);
		setFilterByCategory(true);
		setFilterByAnother(false);
		setPage(1);
		get_category_products(id, 1);
	}

	const addToCart = async (product) => {
	    if (product && product !== null && product !== undefined && product.quantity > 0) {
	        await add_item(product);
	        await get_items();
	        await get_total();
	        await get_item_total();
	    }
	}

	const showProducts = () => {
		let display = []
		let items = [];

        if (filterByCategory) {
            items = Array.isArray(category_products) ? category_products : [];
        }
        else if (filterByAnother) {
            items = Array.isArray(filtered_products) ? filtered_products : [];
        }
        else {
            items = Array.isArray(search_products) && search_products.length > 0 ? search_products : products;
        }

		if (items && items.length > 0 && categories) {
            items.forEach((product, index) => {
				display.push(
					<div key={index} className='product-card-wrapper'>
						<ShopCard product={product} addToCart={addToCart} />
					</div>
				);
			})
		}
		return display;
	}

	const handleNextPage = () => {
        if (productsState.next) {
            setPage(page + 1);
        }
    };

    const handlePreviousPage = () => {
        if (productsState.previous && page > 1) {
            setPage(page - 1);
        }
    };

	return (
		<Layout>
			<div className='container-banner-all shop'>
				<h3>Todos los productos</h3>
			</div>
			<form className='container-categories-shop'>
				{categories && categories.map((category, index) => {
					return (
						<button className='container-category-shop' key={index} type='submit' onClick={e => onSubmitCategory(e, category.id)}>
							<img src={category.thumbnail} alt={`Category ${index}`} />
							<p>{category.name}</p>
						</button>
					)
				})}
			</form>
			<div className='container-filter-search'>
				<form className='input-search-products' onSubmit={e => onSubmitSearch(e)} ref={searchFormRef}>
					<div className='container-search-input'>
						<input className='input-search' placeholder='Buscar' onChange={onSearchChange} value={searchProduct} />
						<div className='search-button'>
							<button className='icon-search-blog' type='submit'><PiMagnifyingGlass className='icon-search-glass' /></button>
						</div>
					</div>
				</form>
				<form onSubmit={e => onSubmitFilter(e)} className='container-filter-products'>
					<Disclosure as="div" className='contain-disclosure'>
	          			{({ open }) => (
	            			<>
	              				<Disclosure.Button className='disclosure-filter'>
	                				<span>Precio</span>
	                				{open ? <FaChevronUp /> : <FaChevronDown />}
	              				</Disclosure.Button>
	              				<Disclosure.Panel className='disclosure-panel'>
	                				{prices && prices.map((price, index) => {
	                					if (price.id === 0) {
	                						return (
	                							<div className='input-category-wrapper' key={index}>
	                								<input 
	                									onChange={e => onChange(e)}
	                									value={price.name}
	                									name='price_range'
	                									type='radio'
	                									className=''
	                									defaultChecked
	                								/>
	                								<label>{price.name}</label>
	                							</div>
	                						)
	                					} else {
	                						return (
	                							<div className='input-category-wrapper' key={index}>
	                								<input
	                									onChange={e => onChange(e)}
	                									value={price.name}
	                									name='price_range'
	                									type='radio'
	                									className=''
	                								/>
	                								<label>{price.name}</label>
	                							</div>
	                						)
	                					}
	                				})

	                				}
	              				</Disclosure.Panel>
	            			</>
	          			)}
	        		</Disclosure>
	        		<Disclosure as="div" className='contain-disclosure'>
	          			{({ open }) => (
	            			<>
	              				<Disclosure.Button className='disclosure-filter'>
	                				<span>Material</span>
	                				{open ? <FaChevronUp /> : <FaChevronDown />}
	              				</Disclosure.Button>
	              				<Disclosure.Panel className='disclosure-panel'>
	                				{materials && materials.map((material, index) => {
	                						return (
	                							<div className='input-category-wrapper' key={index}>
	                								<input
	                									onChange={e => onChange(e)}
	                									value={material.name}
	                									name='material_type'
	                									type='radio'
	                									className=''
	                								/>
	                								<label>{material.name}</label>
	                							</div>
	                						)
	                					
	                				})

	                				}
	              				</Disclosure.Panel>
	            			</>
	          			)}
	        		</Disclosure>
	        		<Disclosure as="div" className='contain-disclosure'>
	          			{({ open }) => (
	            			<>
	              				<Disclosure.Button className='disclosure-filter'>
	                				<span>Filtros</span>
	                				{open ? <FaChevronUp /> : <FaChevronDown />}
	              				</Disclosure.Button>
	              				<Disclosure.Panel className='disclosure-panel'>
								    <div className='select-filter-wrapper'>
								        <label>Ver por</label>
								        <select
									        id='sortBy'
									        name='sortBy'
									        onChange={e => onChange(e)}
									        value={sortBy}
								        >
									        <option value='price'>Precio</option>
									        <option value='name'>Nombre</option>
									        <option value='date_created'>Fecha</option>
									        <option value='sold'>Mas vendidos</option>
								        </select>
								    </div>
								    <div className='select-filter-wrapper'>
									    <label>Ordenar</label>
									    <select
											id='order'
											name='order'
											onChange={e => onChange(e)}
											value={order}
									    >
											<option value='asc'>A - Z</option>
											<option value='desc'>Z - A</option>
									    </select>
	              					</div>
	              				</Disclosure.Panel>
	            			</>
	          			)}
	        		</Disclosure>
	        		<button className='submit-button-filter' type='submit'>Buscar</button>
				</form>
				</div>
			<div className='container-product-pagination'>
				<div className='container-shop'>
					{products && showProducts()}
				</div>
				<div className="pagination-controls">
                	<button className='pagination-button' onClick={handlePreviousPage} disabled={!productsState || (productsState.previous === null&& page === 1)}>
                    	<FaAngleLeft className='pagination-button-icon' />Anterior
                	</button>
                	<button className='pagination-button' onClick={handleNextPage} disabled={!productsState || productsState.next === null}>
                    	Siguiente <FaAngleRight className='pagination-button-icon' />
                	</button>
            	</div>
			</div>
		</Layout>
	);
};

const mapStateToProps = state => ({
	categories: state.Categories.categories,
	products: state.Products.products,
	filtered_products: state.Products.filtered_products,
	category_products: state.Products.category_products,
	search_products: state.Products.search_products,
	count: state.Products.count,
	next: state.Products.next,
	previous: state.Products.previous,
})

export default connect(mapStateToProps, {
	get_categories,
	get_products,
	get_filtered_products,
	get_category_products,
	get_search_products,
	get_items,
	get_total,
	get_item_total,
	add_item,
}) (Shop)