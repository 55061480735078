import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../components/navigation/Navbar';
import { check_authenticated, load_user, refresh} from '../redux/actions/auth';
import { connect } from 'react-redux';
import { useEffect } from 'react';


const LayoutWithoutFooter = (props) => {

	useEffect(() => {
		props.refresh()
		props.check_authenticated()
		props.load_user()
	}, []); 

	return (
		<div>
			<Navbar />
			<ToastContainer autoClose={5000} />
			{props.children}
		</div>
	);
};

export default connect(null, {
	check_authenticated,
	load_user,
	refresh
}) (LayoutWithoutFooter)