import Layout from '../../hocs/Layout';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { signup } from '../../redux/actions/auth';
import { Oval } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import '../../styles/auth.css';


const Signup = ({
 	signup,
 	loading
}) => {

	useEffect(() => {
		window.scrollTo(0,0)
	}, []);

	const [passwordType, setPasswordType] = useState('password');
	const [iconShow, setIconShow] = useState(false);
	const [rePasswordType, setRePasswordType] = useState('password');
	const [reIconShow, setReIconShow] = useState(false);
	const [accountCreated, setAccountCreated] = useState(false);
	const [formData, setFormData] = useState({
		first_name: '',
		last_name: '',
		email: '',
		password: '',
		re_password: ''
	});

	const {
		first_name,
		last_name,
		email,
		password,
		re_password
	} = formData;

	const onChange = e => setFormData({...formData, [e.target.name]: e.target.value });

	const onSubmit = e =>{
    	e.preventDefault();
    	signup(first_name, last_name, email, password, re_password);
    	setAccountCreated(true);
    	window.scrollTo(0,0)
  	};

  	const handleShowPassword = () => {
  		if (passwordType === 'password') {
  			setPasswordType('text')
  			setIconShow(!iconShow)
  		}
  		else if (passwordType === 'text') {
  			setPasswordType('password')
  			setIconShow(!iconShow)
  		}
  	}

  	const handleShowRePassword = () => {
  		if (rePasswordType === 'password') {
  			setRePasswordType('text')
  			setReIconShow(!reIconShow)
  		}
  		else if (rePasswordType === 'text') {
  			setRePasswordType('password')
  			setReIconShow(!reIconShow)
  		}	
  	}

  	if (accountCreated && !loading)
		return <Navigate to='/signup-success' />;

	return (
		<Layout>
			<div className='container-banner-all auth'>
				<h3>Registrarse</h3>
			</div>
			<div className='container-form'>
				<form className='form-content-signup' onSubmit={e => onSubmit(e)}>
						<div className='contain-form'>
							<img className='contain-img-auth' src={require(`../../images/logojoyitaoscuro.png`)} />
							<div className='input-auth'>
								<input
									name='first_name'
									value={first_name}
									onChange={e => onChange(e)}
									type='text'
									required
									className='input-contact-form'
								/>
								<div className='label-line'>Nombre</div>
							</div>
							<div className='input-auth'>
								<input
									name='last_name'
									value={last_name}
									onChange={e => onChange(e)}
									type='text'
									required
									className='input-contact-form'
								/>
								<div className='label-line'>Apellido</div>
							</div>
							<div className='input-auth'>
		                    	<input
		                    		type='email'
		                    		name='email'
		                    		value={email}
		                    		onChange={e => onChange(e)}
		                    		className='input-contact-form'
		                    		required
		                    	/>
		                    	<div className='label-line'>Correo Electrónico</div>
		                	</div>
							<div className='input-auth'>
		                    	<input
		                    		type={passwordType}
		                    		name='password'
		                    		value={password}
		                    		onChange={e => onChange(e)}
		                    		className='input-contact-form'
		                    		required
		                    	/>
		                    	<div className='label-line'>Contraseña</div>
		                    	{iconShow ? (
									<FaRegEyeSlash onClick={handleShowPassword} className='password-show-hide' />
								) : (
									<FaRegEye onClick={handleShowPassword} className='password-show-hide' />
								)}
		                	</div>
							<div className='input-auth'>
								<input
									name='re_password'
									value={re_password}
									onChange={e => onChange(e)}
									type={rePasswordType}
									required
									className='input-contact-form'
								/>
								<div className='label-line'>Repita la Contraseña</div>
								{reIconShow ? (
									<FaRegEyeSlash onClick={handleShowRePassword} className='password-show-hide' />
								) : (
									<FaRegEye onClick={handleShowRePassword} className='password-show-hide' />
								)}
							</div>
							{loading ? 
								<button type='button' className='button-form'><Oval
			  						visible={true}
									height="20"
									width="20"
									color="#ddd"
									ariaLabel="oval-loading"
									wrapperStyle={{}}
									wrapperClass=""
									secundaryColor="#ddd"
									/>
								</button> : 
								<button type='submit' className='button-form'>
									Registrarse	
								</button>
							}
							<div>
								<p className='change-login-signup'>¿Ya tienes una cuenta?<Link to='/login' className='link-change' style={{textDecoration: 'none'}}>Inicia sesión</Link></p>
							</div>
					</div>
				</form>
			</div>
		</Layout>
	);
}

const mapStateToProps = state => ({
	loading: state.Auth.loading
})

export default connect(mapStateToProps, {
	signup
}) (Signup)