import {
    SET_PREFERENCE_LOADING,
    REMOVE_PREFERENCE_LOADING,
	CREATE_PREFERENCE_SUCCESS,
    CREATE_PREFERENCE_FAIL,
    GET_PAYMENT_TOTAL_SUCCESS,
    GET_PAYMENT_TOTAL_FAIL,
    SET_PAYMENT_LOADING,
    REMOVE_PAYMENT_LOADING,
    PAYMENT_SUCCESS,
    PAYMENT_FAIL,
    RESET_PAYMENT_INFO,
} from './types'
import { setAlert } from './alert';
import axios from 'axios'
import { get_item_total } from './cart';


export const create_preference = (shipping_price) => async dispatch => {
    dispatch({
        type: SET_PREFERENCE_LOADING
    });

    if(localStorage.getItem('access')){
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        };

        const body = JSON.stringify({
            shipping_price
        });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/payment/mercadopago/preference`, body, config);

            if (res.status === 200) {
                dispatch({
                    type: CREATE_PREFERENCE_SUCCESS,
                    payload: res.data
                });
                dispatch(setAlert('Preferencia de pago creada correctamente', 'good'))
            } else {
                dispatch({
                    type: CREATE_PREFERENCE_FAIL
                });
                dispatch(setAlert('Ocurrió un error, intente más tarde', 'bad'))
            }
            dispatch({
                type: REMOVE_PREFERENCE_LOADING
            });
        } catch(err) {
            dispatch({
                type: CREATE_PREFERENCE_FAIL
            });
            dispatch({
                type: REMOVE_PREFERENCE_LOADING
            });
            dispatch(setAlert('Ocurrió un error de servidor, intente más tarde', 'bad'))
        }
    } else {
        dispatch({
            type: CREATE_PREFERENCE_FAIL
        });
        dispatch({
            type: REMOVE_PREFERENCE_LOADING
        });
        dispatch(setAlert('Error de autenticación, inicia sesión antes de continuar', 'bad'))
    }
};

export const get_payment_total = (shipping_id, coupon_name) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/payment/get-payment-total?shipping_id=${shipping_id}&coupon_name=${coupon_name}`, config);

        if (res.status === 200 && !res.data.error) {
            dispatch({
                type: GET_PAYMENT_TOTAL_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_PAYMENT_TOTAL_FAIL
            });
        }
    } catch(err) {
        dispatch({
            type: GET_PAYMENT_TOTAL_FAIL
        });
    }
}

export const process_payment = (
    shipping_id,
    coupon_name,
    full_name,
    address_line_1,
    address_line_2,
    city,
    state_province_region,
    postal_zip_code,
    country_region,
    telephone_number,
    preference_id
) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`
        }
    };

    const body = JSON.stringify({
        shipping_id,
        coupon_name,
        full_name,
        address_line_1,
        address_line_2,
        city,
        state_province_region,
        postal_zip_code,
        country_region,
        telephone_number,
        preference_id
    });

    dispatch({
        type: SET_PAYMENT_LOADING
    });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/payment/make-payment`, body, config);
        if (res.status === 200 && res.data.success) {
            dispatch({
                type: PAYMENT_SUCCESS
            });
            dispatch(setAlert(res.data.success, 'good'));
            dispatch(get_item_total());
        } else {
            dispatch({
                type: PAYMENT_FAIL
            });
            dispatch(setAlert(res.data.error, 'bad'));
        }
    } catch(err) {
        dispatch({
            type: PAYMENT_FAIL
        });
        dispatch(setAlert('Error processing payment', 'bad'));
    }

    dispatch({
        type: REMOVE_PAYMENT_LOADING
    });
    window.scrollTo(0, 0);
}

export const reset = () => dispatch => {
    dispatch({
        type: RESET_PAYMENT_INFO
    });
};