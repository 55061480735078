//React
import { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router';
import { useParams } from 'react-router';
//Components
import Layout from '../../hocs/Layout';
import { OrderCard } from '../../components/cards/OrderCard';
import { DashboardMenu } from '../../components/aditional/DashboardMenu';
//Redux
import { connect } from 'react-redux';
import { list_orders } from '../../redux/actions/orders';
import { get_items, get_total, get_item_total } from "../../redux/actions/cart";
import { get_order_detail } from '../../redux/actions/orders';
//Styles
import '../../styles/dashboard.css';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const OrderDetail =({
    list_orders,
    get_items,
    get_total,
    get_item_total,
    order,
    isAuthenticated,
    user,
    get_order_detail
})=>{

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [datePublishPost, setDatePublishPost] = useState(null);
    const [step, setStep] = useState(1);
    const params = useParams()
    const transaction_id = params.transaction_id

    function getMonthName(monthNumber) {
        const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        return monthNames[monthNumber];
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getUTCDate();
        const month = date.getUTCMonth();
        const year = date.getUTCFullYear().toString();

        const monthName = getMonthName(month);
        return `${day} de ${monthName}, ${year}`;
    }    

    useEffect(() => {
        if (order && order.date_issued) {
            setDatePublishPost(formatDate(order.date_issued))
        }
        if (order && order.status) {
          setStep(stepMap[order.status]);
          console.log(step)
        }
    }, [order]);

    const statusMap = {
        sin_procesar: 'Sin procesar',
        procesado: 'Procesado',
        en_camino: 'En camino',
        entregado: 'Entregado',
        cancelado: 'Cancelado'
    };

    const stepMap = {
        sin_procesar: 1,
        procesado: 2,
        en_camino: 3,
        entregado: 4,
        cancelado: 0
    };
    
    useEffect(() => {
        if (transaction_id) {
            get_order_detail(transaction_id)
        }
    }, [transaction_id])

    if(!isAuthenticated)
        return <Navigate to="/"/>

    return (
        <Layout>
            <div className='container-banner-all profile'>
                <h3>Detalles de la Orden</h3>
            </div>
            <div className='container-dashboard'>
                <DashboardMenu />
                <div className='container-order-details'>
                    <div className='container-order-detail'>
                        <h1 className='order-detail-title'>Detalles de la Orden</h1>
                        <div className='contain-order-detail'>
                            <div className='order-detail-items'>
                                <p className='detail-item-title'>Número de Orden:</p>
                                <p>{transaction_id && order.transaction_id}</p>
                            </div>
                            <div className='order-detail-items'>
                                <p className='detail-item-title'>Costo Total:</p>
                                <p>$ {order.amount}</p>
                            </div>
                            <div className='order-detail-items'>
                                <p className='detail-item-title'>Estado:</p>
                                <p>{order && statusMap[order.status]}</p>
                            </div>
                        </div>
                    </div>
                    <div className='container-order-products'>
                        <h2 className="sr-only">Productos Comprados</h2>
                        <div className='contain-order-products'>
                            {order.order_items.map((product) => (
                                <div key={product.id} className='order-product-detail-box'>
                                    <div className='product-order-img'>
                                        <img src={product.image} alt={product.name} />
                                    </div>
                                    <p>{product.name}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

const mapStateToProps =state=>({
    order: state.Orders.order,
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user
})

export default connect(mapStateToProps,{
    list_orders,
    get_items,
    get_total,
    get_item_total,
    get_order_detail
}) (OrderDetail)