//Components
import Layout from '../../hocs/Layout';
import { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
//Styles
import '../../styles/static-pages.css';
//Redux
import { create_dates } from '../../redux/actions/dates';


const Dates =({
	create_dates,
})=>{

	const form = useRef();
	const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        available_days: [],
        time_slot: 'morning',
    });

	const {
		first_name,
		last_name,
		email,
		phone,
		available_days,
		time_slot
	} = formData;

	useEffect(() => {
      	window.scrollTo(0,0)
    }, [])

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value });

    const onChangeSelect = e => {
        setFormData({ ...formData, time_slot: e.target.value });
    };

    const handleCheckboxChange = e => {
        const { value, checked } = e.target;
        if (checked) {
            setFormData(prevState => ({
                ...prevState,
                available_days: [...prevState.available_days, value]
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                available_days: prevState.available_days.filter(day => day !== value)
            }));
        }
    };

    const onSubmit = e => {
        e.preventDefault();
		create_dates(first_name, last_name, phone, email, available_days, time_slot);
		form.current.reset();
    };

	return (
		<Layout>
			<div className='container-banner-all contact'>
				<h3>Citas</h3>
			</div>
			<div className='container-dates-form'>
				<form className='contain-dates-form' ref={form} onSubmit={e => onSubmit(e)}>
					<h3>Reserva tu cita</h3>
					<p>Descubre nuestra joyería de oro, crea tus alianzas de boda soñadas, y personaliza tus piezas únicas con nosotros</p>
					<div className='input-auth'>
			            <input
			                type='text'
			                name='first_name'
			                value={first_name}
			                onChange={e => onChange(e)}
			                className='input-contact-form'
			                required
			            />
			            <div className='label-line'>Nombre</div>
			        </div>
			        <div className='input-auth'>
			            <input
			                type='text'
			                name='last_name'
			                value={last_name}
			                onChange={e => onChange(e)}
			                className='input-contact-form'
			                required
			            />
			            <div className='label-line'>Apellido</div>
			        </div>
					<div className='input-auth'>
			            <input
			                type='email'
			                name='email'
			                value={email}
			                onChange={e => onChange(e)}
			                className='input-contact-form'
			                required
			            />
			            <div className='label-line'>Correo Electrónico</div>
			        </div>
			        <div className='input-auth'>
			            <input
			                type='text'
			                name='phone'
			                value={phone}
			                onChange={e => onChange(e)}
			                className='input-contact-form'
			                required
			            />
			            <div className='label-line'>Teléfono</div>
			        </div>
			        <div className='container-checkbox-dates'>
					    <h4>Días disponibles</h4>
					    <div className='contain-checkbox-dates'>
					        <div className='checkbox-dates'>
					            <input 
					                type="checkbox" 
					                name="days" 
					                value="lunes"
					                onChange={e => handleCheckboxChange(e)} 
					            />
					            Lunes
					        </div>
					        <div className='checkbox-dates'>
					            <input 
					                type="checkbox" 
					                name="days" 
					                value="martes"
					                onChange={e => handleCheckboxChange(e)} 
					            />
					            Martes
					        </div>
					        <div className='checkbox-dates'>
					            <input 
					                type="checkbox" 
					                name="days" 
					                value="miercoles"
					                onChange={e => handleCheckboxChange(e)} 
					            />
					            Miércoles
					        </div>
					        <div className='checkbox-dates'>
					            <input 
					                type="checkbox" 
					                name="days" 
					                value="jueves"
					                onChange={e => handleCheckboxChange(e)} 
					            />
					            Jueves
					        </div>
					        <div className='checkbox-dates'>
					            <input 
					                type="checkbox" 
					                name="days" 
					                value="viernes"
					                onChange={e => handleCheckboxChange(e)} 
					            />
					            Viernes
					        </div>
					    </div>
					</div>
					<div className='contain-date-time'>
    					<p>Horario</p>
    					<select className='date-time-select' name={time_slot} onChange={e => onChangeSelect(e)} required>
        					<option value='morning'>Mañana</option>
        					<option value='afternoon'>Tarde</option>
    					</select>
					</div>
					<button className='submit-date-button' type='submit'>Reservar</button>
		        </form>
			</div>
		</Layout>
	)
};

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, {
	create_dates
}) (Dates)