import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import '../../styles/components.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export const SwiperBanner = () => {
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={false}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className='swiper-banner-home earring'>
                        <h3>Aros que Enmarcan Tu Elegancia</h3>
                        <p>Descubre la colección de aros que destacan tu estilo y sofisticación, con diseños que combinan la tradición con la modernidad</p>
                        <Link to='/shop' className='link-shop-category' style={{textDecoration: 'none'}}>Descubre Más</Link>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='swiper-banner-home necklace'>
                        <h3>Collares que Iluminan Tu Presencia</h3>
                        <p>Nuestros collares están diseñados para realzar tu belleza y añadir un toque de sofisticación a cualquier atuendo, convirtiendo cada momento en una declaración de estilo y elegancia</p>
                        <Link to='/shop' className='link-shop-category' style={{textDecoration: 'none'}}>Descubre Más</Link>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='swiper-banner-home bracelet'>
                        <h3>Pulseras que Hablan de Glamour</h3>
                        <p>Cada pulsera es una obra de arte, cuidadosamente elaborada para añadir un toque de glamour y distinción a tu look diario o a eventos especiales</p>
                        <Link to='/shop' className='link-shop-category' style={{textDecoration: 'none'}}>Descubre Más</Link>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    );
}