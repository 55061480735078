// Components
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
// Style
import '../../styles/dashboard.css';
// Icons
import { PiArticle, PiChatText, PiUser, PiHeartLight } from "react-icons/pi";
import { IoBagHandleOutline, IoHeartOutline } from 'react-icons/io5';
import { LuArrowLeft } from "react-icons/lu";


export const DashboardMenu = ({ className }) => {

	const [] = useState();


	return (
		<div className={`container-dashboard-items ${className}`}>
			<NavLink to='/dashboard' className={`dashboard-item ${isActive => isActive ? 'active' : ''}`} style={{textDecoration: 'none'}}><PiUser className='wish-icon' /><p>Perfil</p></NavLink>
			<NavLink to='/wishlist' className={`dashboard-item ${isActive => isActive ? 'active' : ''}`} style={{textDecoration: 'none'}}><PiHeartLight className='wish-icon' /><p>Favoritos</p></NavLink>
			<NavLink to='/orders' className={`dashboard-item ${isActive => isActive ? 'active' : ''}`} style={{textDecoration: 'none'}}><PiArticle className='wish-icon' /><p>Órdenes</p></NavLink>
			<NavLink to='/cart' className={`dashboard-item ${isActive => isActive ? 'active' : ''}`} style={{textDecoration: 'none'}}><IoBagHandleOutline className='wish-icon' /><p>Bolsa de compras</p></NavLink>
		</div>
	)
};