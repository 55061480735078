import React from 'react';
import '../../styles/components.css';
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { useState, useEffect } from 'react';

export const FavoriteHeart = ({ className, addItem, deleteItem, isItemFavorited }) => {

	const [isFavorited, setIsFavorited] = useState(false);

	useEffect(() => {
		setIsFavorited(isItemFavorited);
	}, [isItemFavorited]);

	const handleFavorited = () => {
		if (isFavorited) {
			deleteItem();
			setIsFavorited(false);
		} else {
			addItem();
			setIsFavorited(true);
		}
	}

	return (
		<div className='heart-button' onClick={handleFavorited}>
			{isFavorited ? (
				<IoMdHeart className={`${className}`} />
			) : (
				<IoMdHeartEmpty className={`${className}`} />
			)}
		</div>
	);
};