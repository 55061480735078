//Components
import Layout from '../../hocs/Layout';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { GoogleMapsEmbed } from '../../components/aditional/GoogleMapsEmbed';
//Styles
import '../../styles/static-pages.css';
//Icon
import { BsHeadset } from "react-icons/bs";
import { IoRibbonOutline } from "react-icons/io5";
import { TbTriangleSquareCircleFilled } from "react-icons/tb";
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";


const About = () => {

	useEffect(() => {
      	window.scrollTo(0,0)
    }, [])

	return (
		<Layout>
			<div className='container-banner-all about'>
				<h3>Sobre Nosotros</h3>
			</div>
			<div className='container-about-description'>
				<div className='contain-about-description'>
					<p>Somos La Joyita Oro, una joyería local ubicada en Sarmiento 270, dedicada a ofrecer joyas únicas que celebran los momentos 
					especiales de la vida. Desde nuestra fundación en 1998, nos hemos comprometido a proporcionar joyería de alta calidad que refleje el 
					estilo y la esencia de nuestros clientes.</p>
				</div>
				<div className='about-description-img'>
					<img src={require(`../../images/banner2.jpg`)} alt='banner2' />
				</div>
			</div>
			<div className='container-about-description container-about-description-2'>
				<div className='about-description-img'>
					<img src={require(`../../images/about-venta.webp`)} alt='about-venta' />
				</div>
				<div className='contain-about-description'>
					<p>Nuestra misión es ofrecer una experiencia de compra personalizada y un asesoramiento experto para ayudarte a encontrar la joya perfecta. 
					Valoramos la atención al detalle y el servicio al cliente excepcional, asegurando que cada visita a nuestra tienda sea memorable y 
					gratificante</p>
				</div>
			</div>
			<div className='container-about-characteristics'>
				<div className='contain-about-characteristics'>
					<div className='characteristics-about-box'>
						<BsHeadset className='icon-about-characteristics' />
						<h4>Atención Personalizada</h4>
						<p>Nuestro equipo te ofrece asesoramiento experto para encontrar la joya perfecta según tus gustos y necesidades</p>
					</div>
					<div className='characteristics-about-box'>
						<IoRibbonOutline className='icon-about-characteristics' />
						<h4>Calidad y Exclusividad</h4>
						<p>Seleccionamos joyas de alta calidad y diseño exclusivo para ofrecerte piezas únicas y elegantes</p>
					</div>
					<div className='characteristics-about-box'>
						<TbTriangleSquareCircleFilled className='icon-about-characteristics' />
						<h4>Variedad de Opciones</h4>
						<p>Contamos con una amplia gama de joyas y accesorios para todas las ocasiones, estilos y presupuestos</p>
					</div>
				</div>
			</div>
			<div className='container-about-horizontal'>
				<p>Ofrecemos una amplia gama de productos ya elaborados, asi como productos personalizados hasta relojes y accesorios exclusivos. Además, brindamos 
				servicios de diseño personalizado, reparaciones y asesoramiento de estilo para ayudarte a encontrar la pieza perfecta para cualquier ocasión.</p>
			</div>
			<div className='container-about-contact'>
				<p>Te invitamos a visitarnos en Sarmiento 270 y descubrir nuestras colecciones. Para más información, no dudes en contactarnos a través de 
				+54 9 3574-454270 o lajoyitario1@gmail.com, y síguenos en nuestras redes sociales para conocer las últimas novedades y ofertas.</p>
				<div className='container-maps-about'>
					<h3>Visitanos en nuestros locales</h3>
					<div>
						<GoogleMapsEmbed />
					</div>
				</div>
				<div className='container-contact-social-media'>
				<h3>Nuestras Redes Sociales</h3>
				<div className='container-contact-social'>
					<Link to='https://www.facebook.com/brenda.ledesma.986?locale=es_LA' target='_blank' className='social-contact-links facebook' style={{textDecoration: 'none'}}><FaFacebook /></Link>
					<Link to='https://www.instagram.com/brenledesmats/' target='_blank' className='social-contact-links instagram' style={{textDecoration: 'none'}}><FaInstagram /></Link>
					<Link to='https://www.pinterest.es/bledesma0111/' target='_blank' className='social-contact-links whatsapp' style={{textDecoration: 'none'}}><FaWhatsapp /></Link>
				</div>
			</div>
			</div>
		</Layout>
	)
};

export default About;