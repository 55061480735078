import Layout from '../../hocs/Layout';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { login } from '../../redux/actions/auth';
import { Oval } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import '../../styles/auth.css';
import { Navigate } from 'react-router';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";


const Login = ({
 	login, 
 	loading,
 	isAuthenticated
}) => {

	useEffect(() => {
		window.scrollTo(0,0)
	}, []);

	const [activated, setActivated] = useState(false);
	const [passwordType, setPasswordType] = useState('password');
	const [iconShow, setIconShow] = useState(false);
	const [formData, setFormData] = useState({
		email: '',
		password: ''
	});

	const {
		email,
		password
	} = formData;

	const handleShowPassword = () => {
  		if (passwordType === 'password') {
  			setPasswordType('text')
  			setIconShow(!iconShow)
  		}
  		else if (passwordType === 'text') {
  			setPasswordType('password')
  			setIconShow(!iconShow)
  		}
  	}

	const onChange = e => setFormData({...formData, [e.target.name]: e.target.value });

	const onSubmit = e =>{
    	e.preventDefault();
    	login(email, password);
    	setActivated(true);
    	window.scrollTo(0,0)
  	};

  	if (activated && isAuthenticated && !loading)
		return <Navigate to='/' />;

	return (
		<Layout>
			<div className='container-banner-all auth'>
				<h3>Iniciar Sesión</h3>
			</div>
			<div className='container-form'>
				<form className='form-content' onSubmit={e => onSubmit(e)}>
					<div className='contain-form'>
						<img className='contain-img-auth' src={require(`../../images/logojoyitaoscuro.png`)} />
						<div className='input-auth'>
		                    <input
		                    	type='email'
		                    	name='email'
		                    	value={email}
		                    	onChange={e => onChange(e)}
		                    	className='input-contact-form'
		                    	required
		                    />
		                    <div className='label-line'>Correo Electrónico</div>
		                </div>
		                <div className='input-auth'>
		                    <input
		                    	type={passwordType}
		                    	name='password'
		                    	value={password}
		                    	onChange={e => onChange(e)}
		                    	className='input-contact-form'
		                    	required
		                    />
		                    <div className='label-line'>Contraseña</div>
		                    {iconShow ? (
								<FaRegEyeSlash onClick={handleShowPassword} className='password-show-hide' />
							) : (
								<FaRegEye onClick={handleShowPassword} className='password-show-hide' />
							)}
		                </div>
						{loading ? 
							<button type='button' className='button-form'><Oval
			  					visible={true}
								height="20"
								width="20"
								color="#ddd"
								ariaLabel="oval-loading"
								wrapperStyle={{}}
								wrapperClass=""
								secundaryColor="#ddd"
							/>
							</button> 
						: 
							<button type='submit' className='button-form'>
								Iniciar Sesión	
							</button>
						}
						<div className="text-sm">
			                <Link to="/reset_password" className='link-change' style={{textDecoration: 'none'}}>
			                    ¿Olvidaste tu contraseña?
			                </Link>
			            </div>
			            <div>
							<p className='change-login-signup'>¿Aun no tienes una cuenta?<Link to='/register' className='link-change' style={{textDecoration: 'none'}}>Registrate</Link></p>
						</div>
			        </div>
				</form>
			</div>
		</Layout>
	);
}

const mapStateToProps = state => ({
	loading: state.Auth.loading,
	isAuthenticated: state.Auth.isAuthenticated,
})

export default connect(mapStateToProps, {
	login 
}) (Login)