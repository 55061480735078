import {
    SET_PREFERENCE_LOADING,
    REMOVE_PREFERENCE_LOADING,
    CREATE_PREFERENCE_SUCCESS,
    CREATE_PREFERENCE_FAIL,
    GET_PAYMENT_TOTAL_SUCCESS,
    GET_PAYMENT_TOTAL_FAIL,
    SET_PAYMENT_LOADING,
    REMOVE_PAYMENT_LOADING,
    PAYMENT_SUCCESS,
    PAYMENT_FAIL,
    RESET_PAYMENT_INFO,
} from '../actions/types'


const initialState = {
    preference_id: null,
    payLoading: false,
    made_payment: false,
    original_price: 0.0,
    total_after_coupon: 0.0,
    total_amount: 0.0,
    total_compare_amount: 0.0,
    estimated_tax: 0.0,
    shipping_cost: 0.0,
}

export default function Payment(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case SET_PREFERENCE_LOADING:
            return {
                ...state,
                payLoading: true,
            }
        case REMOVE_PREFERENCE_LOADING:
            return {
                ...state,
                payLoading: false,
            }
        case CREATE_PREFERENCE_SUCCESS:
            return {
                ...state,
                preference_id: payload.preference_id,
            }
        case CREATE_PREFERENCE_FAIL:
            return {
                ...state,
                preference_id: null,
            }
        case GET_PAYMENT_TOTAL_SUCCESS:
            return {
                ...state,
                original_price: payload.original_price,
                total_after_coupon: payload.total_after_coupon,
                total_amount: payload.total_amount,
                total_compare_amount: payload.total_compare_amount,
                estimated_tax: payload.estimated_tax,
                shipping_cost: payload.shipping_cost
            }
        case GET_PAYMENT_TOTAL_FAIL:
            return {
                ...state,
                original_price: 0.00,
                total_after_coupon: 0.00,
                total_amount: 0.00,
                total_compare_amount: 0.00,
                estimated_tax: 0.00,
                shipping_cost: 0.00
            }
        default:
            return state
    }
}