import { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Link } from 'react-router-dom';
import '../../styles/components.css';
import {
	FaEnvelope,
	FaFacebook,
	FaInstagram,
	FaPinterest,
	FaTelegramPlane,
	FaYoutube,
} from "react-icons/fa";


export const ContactForm = () => {

	const form = useRef();
    const [sendedEmail, setSendedEmail] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_mpalr4a', 'template_3pslp3t', form.current, {
                publicKey: 'nfd_phSCzilgjQfDr',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    setSendedEmail(true);
                    form.current.reset();
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

	return (
		<div className='container container-contact-form'>
			<form className='contact-form' ref={form} onSubmit={sendEmail}>
            	<div className='title-contact'>
		            <h3></h3>
		            <p></p>
		        </div>
		        <div className='contain-contact-form'>
		           	<div className='container-contact-divider'>
		                <div className='input-contact'>
		                    <input
		                    	type='text'
		                    	name='user_name'
		                    	className='input-contact-form'
		                    	required
		                    />
		                    <div className='label-line'>Nombre</div>
		                </div>
		                <div className='input-contact'>
		                    <input
		                    	type='email'
		                    	name='user_email'
		                    	className='input-contact-form'
		                    	required
		                    />
		                    <div className='label-line'>Email</div>
		                </div>
		            </div>
		            <div className='container-contact-divider'>
		                <div className='input-contact'>
		                    <input 
		                    	type='text' 
		                    	name='subject' 
		                    	className='input-contact-form' 
		                    	required 
		                    />
		                    <div className='label-line'>Asunto</div>
		                </div>
		                <div className='input-contact'>
		                    <input
								name='phone'
								type='text'
								className='input-contact-form'
								required
							/>
							<div className='label-line'>Teléfono</div>
							<i></i>
		                </div>
		            </div>
		            <div className='input-contact-msg'>
		                <textarea name='message' className='input-contact-form' required></textarea>
		                <div className='label-line'>Mensaje</div>
		            </div>
		        </div>
		        <p className='required-fields'>Todos los campos son obligatorios</p>
		        <div className='container-submit-button'>
		            {sendedEmail ? (
		                <p className='submited-contact-button'>Enviado</p>
		            ) : (
		                <input type='submit' value='Enviar' className='submit-contact-button' />
		            )}
		        </div>
		    </form>
		</div>
	)
};