import { useState, useEffect } from 'react';
import '../../styles/cart.css';
import { IoMdClose, IoIosRefresh } from "react-icons/io";



const CheckoutItem = ({
	index,
	item,
	count,
	update_item,
	remove_item,
	render,
	setRender,
	setAlert,
}) => {

	const [stock, setStock] = useState(false);
	const [formData, setFormData] = useState({
		item_count: 1
	});
	const { item_count } = formData;

	useEffect(() => {
		if (count) {
		setFormData({...formData, item_count: count});
	}
	}, [count]);

	const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

	const onSubmit = e=> {
		e.preventDefault();

		const fetchData = async () => {
			try {
				if (item.product.quantity >= item_count) {
					await update_item(item, item_count);
				} else {
					setAlert('Not enough in stock', 'bad')
				}
				setRender(!render)
			} catch (err) {

			}
		}

		fetchData();
	}

	useEffect(() => {
		if (item && item.product.quantity >= item_count) {
			setStock(true);
		} else {
			setStock(false);
		}
	}, [item.product.quantity, item_count]);

	const removeItemHandler = async () => {
		await remove_item(item);
		setRender(!render);
	}

	return (
		<div className='container-checkout-item'>
			<div className='cart-item-image item-back'>
				<img src={item.product.main_photo} alt={item.product.name} />
			</div>
			<div className='item-product-info'>
				<h4>{item.product.name}</h4>
			</div>
			<div className='container-count'>
				<p>{item && item.count}</p>
			</div>
			<div>
				<p className='cart-item-price'>$ {item.product.final_price}</p>
			</div>
		</div>
	)
}

export default CheckoutItem