//Styles
import '../../styles/navigation.css';
//Icons
import { IoIosSearch } from "react-icons/io";
import { FiSearch } from "react-icons/fi";

const SearchBox = ({
    search,
    onChange,
    onSubmit,
})=>{

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(e);
    }

    return(
        <form onSubmit={handleSubmit} className='form-searchbox'>
            <div>
                <div className='container-searchbox'>
                        <input 
                            className='searchbox-input'
                            type="search"
                            name="search"
                            onChange={e => onChange(e)}
                            value={search}
                            required
                            placeholder="Que buscas hoy?"
                        />
                    <button
                        type="submit"
                        className='searchbox-submit'
                    >
                        <FiSearch className='search-icon' />
                        
                    </button>
                </div>
            </div>
        </form>
    )
}

export default SearchBox