import { FaStar, FaRegStar, FaStarHalfAlt } from 'react-icons/fa';
import '../../styles/cards.css';
import { IoMdHeart } from "react-icons/io";
import { Link } from 'react-router-dom';
import { IoBagHandleOutline } from "react-icons/io5";
import { useEffect, useState } from 'react';
import { IoClose } from "react-icons/io5";


export const WishCard = ({ 
	item,
	removeItem,
	render,
	setRender,
}) => {

	return (
		<div className='container-wish-product-card'>
			<div className='container-wish-product'>
				<div className='wish-product-image'>
					<img src={item && item.product.main_photo} alt={item.product.name} />	
				</div>
				<div className='button-remove-contain'>
					<button onClick={() => removeItem(item)} className='remove-favorite'>
						<IoClose />
					</button>
				</div>
				<div className='wish-item-info'>
					<div className='wish-product-info'>
						<Link to={`/product/${item.product.id}`} className='wish-card-link' style={{textDecoration: 'none'}}>{item.product.name}</Link>
					</div>
				</div>
			</div>
		</div>
	)
};