import {
	CREATE_DATES_SUCCESS,
    CREATE_DATES_FAIL,
} from './types'
import { setAlert } from './alert';
import axios from 'axios'


export const create_dates = ( first_name, last_name, phone, email, available_days, time_slot ) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({
        first_name,
        last_name,
        phone,
        email,
        available_days,
        time_slot,
    });

    console.log('Estas en el redux');

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/dates/new-date`, body, config);

        if (res.status === 201) {
            dispatch({
                type: CREATE_DATES_SUCCESS,
                payload: res.data
            });
            dispatch(setAlert('Tu cita se registro correctamente, revisa tu email o casilla de mensajes', 'good'))
        } else {
            dispatch({
                type: CREATE_DATES_FAIL
            });
            dispatch(setAlert('Hubo un error al registrar su cita, intente nuevamente más tarde', 'bad'))
        }
    } catch(err) {
        dispatch({
            type: CREATE_DATES_FAIL
        });
        dispatch(setAlert('Hubo un error al registrar su cita, intente nuevamente más tarde', 'bad'))
    }
};