export const materials = [
	{
		id: 0,
		name: 'Oro'
	},
	{
		id: 1,
		name: 'Plata 925'
	},
	{
		id: 2,
		name: 'Plata 950'
	},
	{
		id: 3,
		name: 'Plata y Oro'
	},
	{
		id: 4,
		name: 'Acero Quirúrgico'
	},
	{
		id: 5,
		name: 'Acero Dorado'
	},
	{
		id: 6,
		name: 'Acero Blanco'
	},
	{
		id: 7,
		name: 'Otro'
	}
]