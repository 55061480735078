import Layout from '../../hocs/Layout';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { reset_password_confirm } from '../../redux/actions/auth';
import { Oval } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { Navigate } from 'react-router';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import '../../styles/auth.css';


const ResetPasswordConfirm = ({
 	reset_password_confirm, loading
}) => {

	useEffect(() => {
		window.scrollTo(0,0)
	}, []);

	const params = useParams()

	const [passwordType, setPasswordType] = useState('password');
	const [iconShow, setIconShow] = useState(false);
	const [rePasswordType, setRePasswordType] = useState('password');
	const [reIconShow, setReIconShow] = useState(false);
	const [requestSent, setRequestSent] = useState(false);
	const [formData, setFormData] = useState({
		new_password: '',
		re_new_password: ''
	});

	const {
		new_password,
		re_new_password
	} = formData;

	const onChange = e => setFormData({...formData, [e.target.name]: e.target.value });

	const onSubmit = e =>{
    	e.preventDefault();
    	const uid = params.uid
    	const token = params.token

    	reset_password_confirm(uid, token, new_password, re_new_password)
    	if (new_password === re_new_password) {
    		setRequestSent(true);
    	}
  	};

  	const handleShowPassword = () => {
  		if (passwordType === 'password') {
  			setPasswordType('text')
  			setIconShow(!iconShow)
  		}
  		else if (passwordType === 'text') {
  			setPasswordType('password')
  			setIconShow(!iconShow)
  		}
  	}

  	const handleShowRePassword = () => {
  		if (rePasswordType === 'password') {
  			setRePasswordType('text')
  			setReIconShow(!reIconShow)
  		}
  		else if (rePasswordType === 'text') {
  			setRePasswordType('password')
  			setReIconShow(!reIconShow)
  		}	
  	}

  	if (requestSent && !loading)
  		return <Navigate to='/' />;

	return (
		<Layout>
			<div className='container-banner-all auth'>
				<h3>Confirmar Nueva Contraseña</h3>
			</div>
			<div className='container-form'>
				<form className='form-content-signup' onSubmit={e => onSubmit(e)}>
					<div className='contain-form'>
						<img className='contain-img-auth' src={require(`../../images/logojoyitaoscuro.png`)} />
						<div className='input-auth'>
		                    <input
		                    	type={passwordType}
		                    	name='new_password'
		                    	value={new_password}
		                    	onChange={e => onChange(e)}
		                    	className='input-contact-form'
		                    	required
		                    />
		                    <div className='label-line'>Contraseña</div>
		                    {iconShow ? (
								<FaRegEyeSlash onClick={handleShowPassword} className='password-show-hide' />
							) : (
								<FaRegEye onClick={handleShowPassword} className='password-show-hide' />
							)}
		                </div>
						<div className='input-auth'>
							<input
								name='re_new_password'
								value={re_new_password}
								onChange={e => onChange(e)}
								type={rePasswordType}
								required
								className='input-contact-form'
							/>
							<div className='label-line'>Repita la Contraseña</div>
							{reIconShow ? (
								<FaRegEyeSlash onClick={handleShowRePassword} className='password-show-hide' />
							) : (
								<FaRegEye onClick={handleShowRePassword} className='password-show-hide' />
							)}
						</div>
						{loading ? 
							<button type='button' className='button-form'><Oval
  								visible={true}
								height="20"
								width="20"
								color="#ddd"
								ariaLabel="oval-loading"
								wrapperStyle={{}}
								wrapperClass=""
								secundaryColor="#ddd"
							/>
							</button>
						: 
							<button type='submit' className='button-form'>
								Cambiar contraseña
							</button>
						}
					</div>
				</form>
			</div>
		</Layout>
	);
}

const mapStateToProps = state => ({
	loading: state.Auth.loading
})

export default connect(mapStateToProps, {
	reset_password_confirm 
}) (ResetPasswordConfirm)