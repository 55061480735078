// Components
import Layout from '../../hocs/Layout';
import { DashboardMenu } from '../../components/aditional/DashboardMenu';
import ShippingForm from '../../components/cart/ShippingForm';
import CheckoutItem from '../../components/cart/CheckoutItem';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';
import { Oval } from 'react-loader-spinner';
import { Navigate } from 'react-router';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// Styles
import '../../styles/cart.css';
// Redux
import { connect } from 'react-redux';
import { create_preference, get_payment_total, process_payment } from "../../redux/actions/payment";
import { remove_item, update_item, get_items, get_total, get_item_total } from "../../redux/actions/cart";
import { get_shipping_options } from '../../redux/actions/shipping';
import { get_user_profile } from '../../redux/actions/profile';
import { setAlert } from '../../redux/actions/alert';
import { check_coupon } from '../../redux/actions/coupons'
// Icons
import { PiArticle, PiChatText, PiUser, PiHeartLight } from "react-icons/pi";
import { IoBagHandleOutline, IoHeartOutline } from 'react-icons/io5';
import { LuArrowLeft } from "react-icons/lu";


const Checkout = ({
	process_payment,
	get_payment_total,
	original_price,
	total_after_coupon,
	total_amount,
	total_compare_amount,
	estimated_tax,
	shipping_cost,
	get_shipping_options,
	shipping,
	get_user_profile,
	profile,
	get_items,
	items,
	get_total,
	get_item_total,
	amount,
	total_items,
	isAuthenticated,
	remove_item,
	update_item,
	setAlert,
	create_preference,
	preference_id,
	payLoading,
	user,
	check_coupon,
	coupon,
}) => {

	initMercadoPago("APP_USR-183f9b38-53f8-4630-a76b-6612970f40a5", {locale: "es-AR"});

	const [preferenceId, setPreferenceId] = useState('');
	const [shippingOption, setShippingOption] = useState(null);
	const [confirmPay, setConfirmPay] = useState(false);
	const [formData, setFormData] = useState({
        full_name: profile.full_name,
        address_line_1: profile.address_line_1,
        address_line_2: profile.address_line_2,
        city: profile.city,
        state_province_region: profile.state_province_region,
        postal_zip_code: profile.zipcode,
        country_region: 'Argentina',
        telephone_number: profile.phone,
        coupon_name: '',
        shipping_id: 0,
    });

    const { 
        full_name,
        address_line_1,
        address_line_2,
        city,
        state_province_region,
        postal_zip_code,
        country_region,
        telephone_number,
        coupon_name,
        shipping_id,
    } = formData;

	const [data, setData] = useState({
        instance: {}
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        get_items();
        get_total();
        get_item_total();
		get_shipping_options();
		get_user_profile();
    }, [])

    const buy = async e => {
        e.preventDefault();
        setConfirmPay(true);
        await create_preference(shipping_cost)
        if (preference_id) {
	        if (coupon && coupon !== null && coupon !== undefined) {
	          	process_payment(
	              	shipping_id,
	              	coupon.name,
	              	full_name,
	              	address_line_1,
	              	address_line_2,
	              	city,
	              	state_province_region,
	              	postal_zip_code,
	              	country_region,
	              	telephone_number,
	              	preference_id
	          	);
	        } else {
	          	process_payment(
	              	shipping_id,
	              	'',
	              	full_name,
	              	address_line_1,
	              	address_line_2,
	              	city,
	              	state_province_region,
	              	postal_zip_code,
	              	country_region,
	              	telephone_number,
	              	preference_id
	          	);
	      	}
      	}
    }

    const apply_coupon = async e => {
      	e.preventDefault();
        check_coupon(coupon_name);
    };

    useEffect(() => {
		if (preference_id) {
			setPreferenceId(preference_id)
		}
	}, []);

	useEffect(() => {
    	if (coupon && coupon !== null && coupon !== undefined)
        	get_payment_total(shipping_id, coupon.name);
    	else
        	get_payment_total(shipping_id, 'default');
  	}, [shipping_id, coupon]);

    const renderShipping = () => {
        if (shipping && shipping !== null && shipping !== undefined) {
            return (
                <div className='shipping-items'>
                    {
                        shipping.map((shipping_option, index) => (
                            <div key={index} className='shipping-item'>
                                <input
                                    onChange={e => onChange(e)}
                                    value={shipping_option.id}
                                    name='shipping_id'
                                    type='radio'
                                    required
                                />
                                <label className='ml-4'>
                                    {shipping_option.name} - ${shipping_option.price} ({shipping_option.time_to_delivery})
                                </label>
                            </div>
                        ))
                    }
                </div>
            );
        }
    };

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const showItems = () => {
    	return (
    		<div>
				{
					items &&
					items !== null &&
					items !== undefined &&
					items.length !== 0 &&
					items.map((item, index) => {
						let count = item.count;

						return (
							<div key={index}>
								<CheckoutItem
									item={item}
									count={count}
								/>
							</div>
						)
					})
				}
			</div>
    	)
    };

    if(!isAuthenticated)
        return <Navigate to="/" />

	return (
		<Layout>
			<div className='container-banner-all cart'>
				<h3>Cartera de pago</h3>
			</div>
			<div className='container-dashboard'>
				<DashboardMenu />
				<div className='container-cart-checkout'>
					<div className='container-all-checkout'>
						{
							items &&
							items !== null &&
							items !== undefined &&
							items.length !== 0 ? (
								<div className='container-items-cart'>
									<div className='container-checkout-item phone-titles-cart'>
										<h4>Producto</h4>
										<div></div>
										<h4>Cantidad</h4>
										<h4>Precio</h4>
									</div>
									{showItems()}
								</div>
							) : (
								<div className='container-empty-cart'>
									<div className='container-cart-title'>
										<h2>No hay productos en tu bolsa</h2>
									</div>
									<div className='cart-back-to-goods'>
										<Link to='/shop' className='link-back-to-shop' style={{textDecoration: 'none'}}>Volver a la tienda</Link>
									</div>
								</div>
							)
						}
					</div>
					<div>
					<ShippingForm 
						renderShipping={renderShipping}
						onChange={onChange}
						user={user}
						profile={profile}
						full_name={full_name}
			            address_line_1={address_line_1}
			            address_line_2={address_line_2}
			            city={city}
			            state_province_region={state_province_region}
			            postal_zip_code={postal_zip_code}
			            telephone_number={telephone_number}
			            buy={buy}
						apply_coupon={apply_coupon}
						total_amount={total_amount}
						original_price={original_price}
						estimated_tax={estimated_tax}
						shipping_cost={shipping_cost}
						shipping={shipping}
						shipping_id={shipping_id}
						confirmPay={confirmPay}
					/>
					{preference_id && confirmPay ? 
						<><Wallet initialization={{ preferenceId: preference_id }} /></>
						:
						<></>}
					</div>
				</div>
			</div>
		</Layout>
	)
};

const mapStateToProps = state => ({
	isAuthenticated: state.Auth.isAuthenticated,
	items: state.Cart.items,
	amount: state.Cart.amount,
	total_items: state.Cart.total_items,
	preference_id: state.Payment.preference_id,
	payLoading: state.Payment.payLoading,
	shipping: state.Shipping.shipping,
	profile: state.Profile.profile,
	user: state.Auth.user,
	coupon: state.Coupons.coupon,
	original_price: state.Payment.original_price,
	total_after_coupon: state.Payment.total_after_coupon,
	total_amount: state.Payment.total_amount,
	total_compare_amount: state.Payment.total_compare_amount,
	estimated_tax: state.Payment.estimated_tax,
	shipping_cost: state.Payment.shipping_cost,
})

export default connect(mapStateToProps, {
	get_items,
	get_total,
	get_item_total,
	remove_item,
	update_item,
	setAlert,
	create_preference,
	get_shipping_options,
	get_user_profile,
	check_coupon,
	get_payment_total,
	process_payment,
}) (Checkout)