// Components
import { Fragment, useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import Alert from '../../components/alert';
import { Navigate } from 'react-router';
import SearchBox from './SearchBox';
// Styles
import '../../styles/navigation.css';
// Redux
import { connect } from 'react-redux';
import { get_item_total } from '../../redux/actions/cart';
import { check_authenticated } from '../../redux/actions/auth';
import { get_search_products } from '../../redux/actions/products';
// Icons
import { IoBagHandleOutline, IoHeartOutline, IoHomeOutline } from 'react-icons/io5';
import { AiOutlineUser, AiOutlineShop } from "react-icons/ai";
import { HiOutlineMapPin } from "react-icons/hi2";
import { PiCallBell } from "react-icons/pi";
import { LuPhone } from "react-icons/lu";
import { TbInfoSquare } from "react-icons/tb";


function Navbar({
	isAuthenticated,
	check_authenticated,
	user,
	get_item_total,
	categories,
	total_items,
	get_search_products,
}) {

	useEffect(() => {
		check_authenticated();

		const handleScroll = () => {
			const navbar = document.querySelector('.container-nav');
			if (window.scrollY > 50) { // Cambia el valor según sea necesario
				navbar.classList.add('scrolled');
			} else {
				navbar.classList.remove('scrolled');
			}
		};

		window.addEventListener('scroll', handleScroll);

		// Limpia el evento cuando el componente se desmonta
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [check_authenticated]);

	useEffect(() => {
		get_item_total();
	}, []);

	const [redirect, setRedirect] = useState(false);
	const [render, setRender] = useState(false);
	const [menuOpen, setMenuOpen] = useState(false);
	const [searchForm, setSearchForm] = useState('');

	const onSubmit = e => {
		e.preventDefault();
		console.log(searchForm);
		get_search_products(searchForm, 1);
		setRender(!render);
	};

	const onChange = e => setSearchForm(e.target.value);

	if(render){
    	return <Navigate to='/search' />;
  	}

	const toggleMenu = () => {
    	setMenuOpen(!menuOpen);
  	};

	if (redirect){
		window.location.reload(false);
		return <Navigate to='/' />
	}

	return (
		<>	
			<div className='container-nav'>
				<div className='container-navbar'>
					<div className='container-navlinks'>
						<Link to='/contact#map-section' className='navlinks-icon-link' style={{textDecoration: 'none'}}><HiOutlineMapPin /></Link>
						<Link to='/dates' className='navlinks-icon-link' style={{textDecoration: 'none'}}><PiCallBell /></Link>
						<SearchBox
							search={searchForm}
					        onChange={onChange}
					        onSubmit={onSubmit}
					    />
					</div>
					<div className='container-logo'>
						<Link to='/' style={{textDecoration: 'none'}}><img src={require(`../../images/logojoyita.png`)} /></Link>
					</div>
					<div className='container-navlinks'>
						<Link to='/cart' className='navlinks-icon-link' style={{textDecoration: 'none'}}><IoBagHandleOutline /><span>{total_items && total_items}</span></Link>
						<Link to='/wishlist' className='navlinks-icon-link' style={{textDecoration: 'none'}}><IoHeartOutline /></Link>
						<Link to={isAuthenticated ? '/dashboard' : '/login'} className='navlinks-icon-link' style={{textDecoration: 'none'}}><AiOutlineUser /></Link>
					</div>
				</div>
				<div className='container-nav-phone'>
					<div className='container-navphone-img'>
						<Link to='/' className='' style={{textDecoration: 'none'}}>
							<img src={require(`../../images/logojoyita.png`)} />
						</Link>
					</div>
					<div className='contain-nav-phone'>
						<Link to='/wishlist' className='navlinks-icon-link' style={{textDecoration: 'none'}}><IoHeartOutline /></Link>
						<Link to='/cart' className='navlinks-icon-link' style={{textDecoration: 'none'}}><IoBagHandleOutline /><span>{total_items && total_items}</span></Link>
						<button className='nav-open-btn' id='menu-toggle' aria-label='Toggle Menu' onClick={toggleMenu}>
							<span className={`line ${menuOpen ? 'line-1' : ''}`}></span>
							<span className={`line ${menuOpen ? 'line-2' : ''}`}></span>
							<span className={`line ${menuOpen ? 'line-3' : ''}`}></span>
						</button>
					</div>
				</div>
				<div className='container-navbar-navigation'>
					<Link to='/' className='navbar-navigation' style={{textDecoration: 'none'}}>Inicio</Link>
					<Link to='/shop' className='navbar-navigation' style={{textDecoration: 'none'}}>Tienda</Link>
					<Link to='/about' className='navbar-navigation' style={{textDecoration: 'none'}}>Sobre Nosotros</Link>
					<Link to='/contact' className='navbar-navigation' style={{textDecoration: 'none'}}>Contacto</Link>
				</div>
				<div className={`phone-navbar-navigation navbar ${menuOpen ? 'menu-active' : ''}`}>
					<Link to='/' className='navigation-phone' style={{textDecoration: 'none'}}><IoHomeOutline />Inicio</Link>
					<Link to='/shop' className='navigation-phone' style={{textDecoration: 'none'}}><AiOutlineShop />Tienda</Link>
					<Link to={isAuthenticated ? '/dashboard' : '/login'} className='navigation-phone' style={{textDecoration: 'none'}}><AiOutlineUser />{isAuthenticated ? 'Perfil' : 'Ingresar'}</Link>
					<Link to='/dates' className='navigation-phone' style={{textDecoration: 'none'}}><PiCallBell />Haz una cita</Link>
					<Link to='/contact#map-section' className='navigation-phone' style={{textDecoration: 'none'}}><HiOutlineMapPin />Encuentranos</Link>
					<Link to='/about' className='navigation-phone' style={{textDecoration: 'none'}}><TbInfoSquare />Sobre Nosotros</Link>
					<Link to='/contact' className='navigation-phone' style={{textDecoration: 'none'}}><LuPhone />Contacto</Link>
				</div>
			</div>
			<Alert />
		</>
	);
};

const mapStateToProps = state => ({
	isAuthenticated: state.Auth.isAuthenticated,
	user: state.Auth.user,
	categories: state.Categories.categories,
	total_items: state.Cart.total_items,
})

export default connect(mapStateToProps, {
	get_item_total,
	check_authenticated,
	get_search_products
}) (Navbar)