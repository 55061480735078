// Components
import Layout from '../../hocs/Layout';
import { DashboardMenu } from '../../components/aditional/DashboardMenu';
import { OrderCard } from '../../components/cards/OrderCard';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { Link } from 'react-router-dom';
// Styles
import '../../styles/dashboard.css';
// Redux
import { connect } from 'react-redux';
import { list_orders } from '../../redux/actions/orders';


const Orders = ({
	list_orders,
	orders,
}) => {

	const [render, setRender] = useState(false);
	const [redirect, setRedirect] = useState(false);

	useEffect(() => {
        window.scrollTo(0, 0);
        list_orders();
    }, [])

	const showOrders = () => {
		let display = [];

    	if (orders &&
			orders !== null &&
			orders !== undefined &&
			orders.length !== 0) {
				orders.forEach((order, index) => {
					display.push(
						<div key={index} className='cards-contain-wish'>
							<OrderCard
								order={order}
								render={render}
								setRender={setRender}
							/>
						</div>	
					)
				}
			)
		}
		return display;
    };

	if (redirect) {
		window.location.reload(false);
		return <Navigate to='/' />
	}

	return (
		<Layout>
			<div className='container-banner-all profile'>
				<h3>Órdenes</h3>
			</div>
			<div className='container-dashboard'>
				<DashboardMenu />
				<div className='container-orders'>
					{showOrders()}
				</div>
			</div>
		</Layout>
	)
}

const mapStateToProps = state => ({
	isAuthenticated: state.Auth.isAuthenticated,
	orders: state.Orders.orders,
})

export default connect(mapStateToProps, {
	list_orders,
}) (Orders)