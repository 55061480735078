import Layout from '../../hocs/Layout';
import '../../styles/static-pages.css';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
//Components
import { ContactForm } from '../../components/aditional/ContactForm';
import { GoogleMapsEmbed } from '../../components/aditional/GoogleMapsEmbed';
//Icons
import { LuPhone, LuMapPin } from "react-icons/lu";
import { BsEnvelope } from "react-icons/bs";
import {
	FaEnvelope,
	FaFacebook,
	FaInstagram,
	FaWhatsapp,
} from "react-icons/fa";


const Contact =({
})=>{

	useEffect(() => {
      	window.scrollTo(0,0)
    }, [])

    const { hash } = useLocation();

  	useEffect(() => {
    	if (hash) {
      		const element = document.querySelector(hash);
      		if (element) {
        		element.scrollIntoView({ behavior: 'smooth' });
      		}
    	}
  	}, [hash]);

	return (
		<Layout>
			<div className='container-banner-all contact'>
				<h3>Contacto</h3>
			</div>
			<div className='container-boxes-contact'>
				<div className='contain-boxes-contact'>
					<div className='box-contact-icon'>
						<LuMapPin />
					</div>
					<h3>Ubicación</h3>
					<p>Rio Primero, Córdoba - Argentina</p>
				</div>
				<div className='contain-boxes-contact'>
					<div className='box-contact-icon'>
						<LuPhone />
					</div>
					<h3>Llámanos</h3>
					<p>+54 9 3574-454270</p>
				</div>
				<div className='contain-boxes-contact'>
					<div className='box-contact-icon'>
						<BsEnvelope />
					</div>
					<h3>Envíanos un mensaje</h3>
					<p>lajoyitario1@gmail.com</p>
				</div>
			</div>
			<div className='container-maps-form' id='map-section'>
				<div>
					<GoogleMapsEmbed />
				</div>
				<ContactForm />
			</div>
			<div className='container-contact-social-media'>
				<h3>Nuestras Redes Sociales</h3>
				<div className='container-contact-social'>
					<Link to='https://www.facebook.com/lajoyita.ok' target='_blank' className='social-contact-links facebook' style={{textDecoration: 'none'}}><FaFacebook /></Link>
					<Link to='https://www.instagram.com/lajoyitaoro.ok/' target='_blank' className='social-contact-links instagram' style={{textDecoration: 'none'}}><FaInstagram /></Link>
					<Link to='https://wa.me/+543574454270?text=Buenos%20días,%20La%20Joyita,%20quiero%20información%20de%20los%20productos.' target='_blank' className='social-contact-links whatsapp' style={{textDecoration: 'none'}}><FaWhatsapp /></Link>
				</div>
			</div>
		</Layout>
	)
};

export default Contact;