import Layout from '../../hocs/Layout';
import { FaCircle } from 'react-icons/fa';
import '../../styles/static-pages.css';
import { useEffect } from 'react';


const FrequentQuestions =({
})=>{

	useEffect(() => {
      	window.scrollTo(0,0)
    }, [])

	return (
		<Layout>
			<div className='container-banner-all policies'>
				<h3>Preguntas Frecuentes</h3>
			</div>
			<div className='container'>
				<div className='container-policies'>
					<h2>Preguntas Frecuentes</h2>
					<h3>¿Cómo puedo realizar una compra en línea?</h3>
					<p>Para realizar una compra, simplemente selecciona los productos que deseas, agrégalos a tu carrito y sigue las 
						instrucciones de pago. Recibirás una confirmación por correo electrónico.
					</p>
					<h3>¿Cuáles son las formas de pago disponibles?</h3>
					<p>Aceptamos pagos mediante tarjetas de crédito, débito, cupón de pago y plataformas como Mercado Pago.</p>
					<h3>¿Es seguro comprar en su tienda en línea?</h3>
					<p>Sí, nuestra tienda utiliza tecnología de encriptación SSL para garantizar que tus datos estén seguros y protegidos.</p>
					<h3>¿Puedo personalizar las joyas?</h3>
					<p>Sí, ofrecemos la opción de personalizar algunas piezas. Para más información, consulta la sección de "Joyas Personalizadas" 
						o contáctanos directamente.
					</p>
					<h3>¿Cómo sé qué talla de anillo necesito?</h3>
					<p>Tenemos una guía de tallas en nuestra página de ayuda. Si necesitas más asistencia, contáctanos para obtener asesoría.</p>
					<h3>¿Hacen envíos internacionales?</h3>
					<p>Sí, realizamos envíos a varios países. Los costos de envío y los tiempos de entrega pueden variar según la ubicación.</p>
					<h3>¿Cuánto tiempo tarda el envío?</h3>
					<p>Los tiempos de envío dependen de tu ubicación. En promedio, los envíos nacionales tardan entre 3 y 7 días hábiles, mientras 
					que los internacionales pueden demorar entre 10 y 15 días.</p>
					<h3>¿Puedo devolver o cambiar una joya?</h3>
					<p>Sí, ofrecemos devoluciones y cambios dentro de un período de 30 días, siempre que el producto esté en perfectas condiciones. 
					Las joyas personalizadas no son elegibles para devolución.</p>
					<h3>¿Qué tipo de garantía tienen las joyas?</h3>
					<p>Ofrecemos una garantía de un año para defectos de fabricación. No cubre daños causados por el uso indebido o desgaste natural.</p>
					<h3>¿Cómo puedo cuidar mis joyas?</h3>
					<p>Recomendamos mantener las joyas alejadas de productos químicos y almacenarlas en un lugar seco y seguro. Limpia tus piezas 
					regularmente con un paño suave.</p>
				</div>
			</div>
		</Layout>
	)
};

export default FrequentQuestions;