import StarsCard from '../products/StarsCard';
import '../../styles/components.css'
import { IoCartOutline } from "react-icons/io5";
import { IoMdHeartEmpty } from "react-icons/io";
import { Link } from 'react-router-dom';


export const HomeCard = ({ product }) => {
	return (
		<div className='container-home-card'>
			<div className='home-card-image'>
				<Link to={`/product/${product.id}`}><img src={product && product.main_photo} alt={product.name} /></Link>
			</div>
			<div className='home-card-info'>
				<Link to={`/product/${product.id}`} style={{textDecoration: 'none'}}><h4 className='home-card-title'>{product.name}</h4></Link>
				<p className='home-card-price'>$ {product.final_price}</p>
			</div>
		</div>
	)
};