import {
	GET_PRODUCTS_SUCCESS,
	GET_PRODUCTS_FAIL,
	GET_PRODUCTS_BY_ARRIVAL_SUCCESS,
	GET_PRODUCTS_BY_ARRIVAL_FAIL,
	GET_PRODUCTS_BY_SOLD_SUCCESS,
	GET_PRODUCTS_BY_SOLD_FAIL,
	GET_PRODUCT_SUCCESS,
	GET_PRODUCT_FAIL,
	SEARCH_PRODUCTS_SUCCESS,
	SEARCH_PRODUCTS_FAIL,
	RELATED_PRODUCTS_SUCCESS,
	RELATED_PRODUCTS_FAIL,
	FILTER_PRODUCTS_SUCCESS,
	FILTER_PRODUCTS_FAIL,
	CATEGORY_PRODUCTS_SUCCESS,
	CATEGORY_PRODUCTS_FAIL,
	HIGHLIGHT_PRODUCTS_SUCCESS,
	HIGHLIGHT_PRODUCTS_FAIL,
} from './types'
import axios from 'axios'


export const get_products = (page = 1, order = 'asc', sortBy = 'name') => async dispatch => {
	const config = {
		headers: {
			'Accept': 'application/json'
		}
	};

	try {
		const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/product/get-products?page=${page}&sortBy=${sortBy}&order=${order}`, config);

		if (res.status === 200) {
			dispatch({
				type: GET_PRODUCTS_SUCCESS,
				payload: res.data
			});
		} else {
			dispatch({
				type: GET_PRODUCTS_FAIL
			});
		}
	} catch(err) {
		dispatch({
			type: GET_PRODUCTS_FAIL
		});
	}
};

export const get_products_by_arrival = () => async dispatch => {
	const config = {
		headers: {
			'Accept': 'application/json'
		}
	};

	try {
		const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/product/get_products?sortBy=date_created&order=desc`, config);
	
		if (res.status === 200) {
			dispatch({
				type: GET_PRODUCTS_BY_ARRIVAL_SUCCESS,
				payload: res.data
			});
		} else {
			dispatch({
				type: GET_PRODUCTS_BY_ARRIVAL_FAIL
			});
		}
	} catch(err) {
		dispatch({
			type: GET_PRODUCTS_BY_ARRIVAL_FAIL
		});
	}
};

export const get_products_by_sold = () => async dispatch => {
	const config = {
		headers: {
			'Accept': 'application/json'
		}
	};

	try {
		const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/product/get_products?sortBy=sold&order=desc&limit=10`, config);
	
		if (res.status === 200) {
			dispatch({
				type: GET_PRODUCTS_BY_SOLD_SUCCESS,
				payload: res.data
			});
		} else {
			dispatch({
				type: GET_PRODUCTS_BY_SOLD_FAIL
			});
		}
	} catch(err) {
		dispatch({
			type: GET_PRODUCTS_BY_SOLD_FAIL
		});
	}
};

export const get_product = (productId) => async dispatch => {
	const config = {
		headers: {
			'Accept': 'application/json'
		}
	};

	try {
		const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/product/product/${productId}`, config);
	
		if (res.status === 200) {
			dispatch({
				type: GET_PRODUCT_SUCCESS,
				payload: res.data
			});
		} else {
			dispatch({
				type: GET_PRODUCT_FAIL
			});
		}
	} catch(err) {
		dispatch({
			type: GET_PRODUCT_FAIL
		});
	}
};

export const get_related_products = (productId) => async dispatch => {
	const config = {
		headers: {
			'Accept': 'application/json'
		}
	};

	try {
		const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/product/related/${productId}`, config);
	
		if (res.status === 200) {
			dispatch({
				type: RELATED_PRODUCTS_SUCCESS,
				payload: res.data
			});
		} else {
			dispatch({
				type: RELATED_PRODUCTS_FAIL
			});
		}
	} catch(err) {
		dispatch({
			type: RELATED_PRODUCTS_FAIL
		});
	}
};

export const get_filtered_products = (material, price_range, sort_by, order, page = 1) => async dispatch => {
	const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({
        material,
        price_range,
        sort_by,
        order
    });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/product/by/search?page=${page}`, body, config);
	
		if (res.status === 200 && !res.data.error) {
            dispatch({
                type: FILTER_PRODUCTS_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: FILTER_PRODUCTS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: FILTER_PRODUCTS_FAIL
        });
    }
}

export const get_search_products = (search, page = 1) => async dispatch => {
	const config = {
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		}
	};

	const body = JSON.stringify({
		search
	});

	try {
		const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/product/search?page=${page}`, body, config);

		if (res.status === 200) {
			dispatch({
				type: SEARCH_PRODUCTS_SUCCESS,
				payload: res.data
			});
		} else {
			dispatch({
				type: SEARCH_PRODUCTS_FAIL
			});
		}
	} catch(err) {
		dispatch({
			type: SEARCH_PRODUCTS_FAIL
		});
	}
};

export const get_category_products = (category_id, page = 1) => async dispatch => {
	const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({
        category_id
    });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/product/by/category?page=${page}&sortBy=date_created&order=desc`, body, config);
	
		if (res.status === 200 && !res.data.error) {
            dispatch({
                type: CATEGORY_PRODUCTS_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: CATEGORY_PRODUCTS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: CATEGORY_PRODUCTS_FAIL
        });
    }
}

export const get_highlighted_products = () => async dispatch => {
	const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/product/highlighted`, config);
	
		if (res.status === 200 && !res.data.error) {
            dispatch({
                type: HIGHLIGHT_PRODUCTS_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: HIGHLIGHT_PRODUCTS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: HIGHLIGHT_PRODUCTS_FAIL
        });
    }
}